import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all verified client accounts
    getAllAccounts(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccountCostCenter(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAccountCostCenter`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    // Arange TC users
    fetchArangeDepositTCUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArangeEquityTCUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Equity Currencies
    fetchArrangeDepositCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeEquityCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Search Equity
    searchDeposit(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchDeposit`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getLastSearch(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearch`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    getSavedSearches(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedSearches`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    deleteSavedSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteSavedDefault(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedDefault`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    //Last Search Equity
    getLastSearchEquity(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearchEquity`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //filter Trade Date
    fetchFilterTradeDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterDepositTradeDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //filter expiry Date
    fetchFilterExpiryDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterDepositExpiryDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getViewSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedDepositTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewAmendedSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedDepositTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewDeposit`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTradeUserOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkDepositTradeUserOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getVerifySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getVerifySelectedSwapTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`depositTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTCUtiJournalCreated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiJournalCreated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAmendSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendSelectedDepositTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCopySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCopySelectedDepositTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkAmendEntryType(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkAmendEntryType`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkUpdateAmendment(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkEquityUpdateAmendment`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    removeTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeAmendmentUpdate(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`depositTradeAmendmentUpdate`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`copySelectedDepositTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /// journal section

    checkJournalTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCreateJournalTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCreateEquityJournalTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkEquityJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createEquityJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewJournalTrade(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewEquityJournalTrade`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCanceledDepositById(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCanceledDepositId`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    autoMatchSameContracts(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`autoMatchSameFutureContracts`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getPairedSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getPairedSelectedFutureTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTCUtiReserved(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiReserved`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkReversalInitiated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkReversalInitiated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendInitialise(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getEquityAmendInitialise`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    tradeInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getReverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getReverseEquityEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    reverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`reverseEquityEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getUndoInitiateEquityReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    undoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`undoInitiateEquityReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendDetailDatas(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendDepositDetailDatas`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    selectTexRateAsExchangeBuySell(ctx, exchange) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectEquityTexRateAsExchangeBuySell`, exchange)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get holidays
    getHolidays(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //send email
    sendInitiateReversalMail(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`sendInitiateReversalMail`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Last 7 Days deposits Trades
    getLast7DaysTradesDeposits(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getLast7DaysTradesDeposits`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //save Default deposit Entry.
    saveDefaultEntry(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveDefaultEntry`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Saved Default Entries
    getSavedDefaultEntries(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedDefaultEntries`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //deposit Order Taken Given Through
    fetchArrangeDepositOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeEquityOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change deposit Status Opr.
    tradeMultipleChangeDepositStatusOpr(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`depositTradesMultipleChangeStatusOpr`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Select deposit Copy Opr.
    tradeMultipleSelectDepositCopyOpr(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`depositTradeMultipleSelectCopyOpr`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    exportXlsx(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportDepositTradesXlsx',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportCsv(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportDepositTradesCsv',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
