<template>
    <div>
      <b-card
        class="mb-1 card_body_bottom"
        :title="$t('deposit.titles.deposit')"
      >
          <hr />
          <b-overlay :show="orderColumnsOverlay">
          <b-row class="mb-2 align-items-center">
  
            <!-- filter deposit date -->
            <b-col cols="6" md="6" xl="3" lg="3" sm="6">
                  <b-input-group class="compact_form_input-group">
                    <flat-pickr
                      :placeholder="$t('deposit.deposit_date')"
                      onClose="testClose"
                      v-model="depositRangeDate"
                      class="form-control compact_form_flatpickr"
                      :config="dateConfig"
                      @on-change="getFilterDepositDate()"
                      
                    />
                    <b-input-group-append>
                      <b-button
                        @click="clearDepositDate()"
                        variant="success"
                        size="sm"
                        
                        >{{ $t("general_buttons.clear") }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
  
            <!-- filter instruction date -->
            <b-col cols="6" md="6" xl="3" lg="3" sm="6">
            <b-input-group>
              <flat-pickr
                :placeholder="$t('deposit.instruction_date')"
                onClose="testClose"
                v-model="instructionRangeDate"
                class="form-control"
                :config="dateConfig"
                @on-change="getFilterInstructionDate()"
                
              />
              <b-input-group-append>
                <b-button
                  @click="clearInstructionDate()"
                  size="sm"
                  variant="success"
                  
                  >{{ $t("general_buttons.clear") }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-col>
  
  
            <!-- list page buttons -->
                <b-col  cols="12" md="12" xl="6" lg="6" sm="12" class="d-flex justify-content-end align-items-center list-pages-buttons">
                  
                  <!-- search for trades -->
                  <b-button
                    v-if="$Can('search_deposit')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-info"
                    size="sm"
                    class="compact_nav_icon-text-button mr-1"
                    @click="searchForTradesModal"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="14"
                    />
                    <span class="trades-font">{{
                      $t("deposit.buttons.search_for_trades")
                    }}</span>
                  </b-button>
                  <!-- run last search -->
                  <b-button
                    v-if="$Can('search_deposit')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-dark"
                    size="sm"
                    class="compact_nav_icon-text-button mr-1"
                    @click="runLastSearch(true)"
                 
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("deposit.buttons.run_last_search")
                    }}</span>
                  </b-button>
                   <!-- enter new trade -->
                   <b-button
                   v-if="$Can('add_deposit')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    size="sm"
                    variant="gradient-success"
                    class="compact_nav_icon-text-button"
                    @click="createNewTrade"
                  >
                    <i class="fa-solid fa-plus" 
                      style="margin-bottom: 0.3rem;"></i>
                    <span class="text-nowrap">{{
                      $t("deposit.buttons.enter_new_trade")
                    }}</span>
                  </b-button>
                </b-col >
          </b-row>
  
          <!-- order columns overlay -->
          <template #overlay>
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <p><strong id="form-confirm-label">{{ $t("general_messages.save_order_columns_message") }}</strong></p>
  
              <p class="mb-0" style="font-size:0.8rem;">{{ $t("general_messages.filter_columns_message") }}</p>
              <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-md-0"
                >
                  <treeselect
                    value-consists-of="LEAF_PRIORITY"
                    :multiple="true"
                    :options="tableColumnsOptions"
                    :clearable="false"
                    :searchable="true"
                    v-model="treeTablecolumns"
                    @select="selectTreeColumn"
                    @deselect="deSelectTreeColumn"
                    valueFormat="object"
                    :limit="9"
                    :placeholder="$t('deposit.get_selected_columns')"
                    v-if="visibleDepositStatusInput"
                    class="treeSelect"
                  />
                </b-col>
              <div class="d-flex justify-content-center mt-1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="gradient-danger"
                  class="mr-3"
                  size="sm"
                  @click="onCancelOrderColumn"
                >
                {{ $t("general_buttons.cancel") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="gradient-warning"
                  size="sm"
                  @click="onSaveOrderColumns"
                >
                {{ $t("general_buttons.save_columns_order") }}
                </b-button>
                
              </div>
            </div>
          </template>
        </b-overlay>
      </b-card>
  
  
      <b-overlay :show="loading" rounded="sm" no-fade>
        
        <b-card>
          <!-- Per Page && filter status && export && legend button -->
          <b-row class="mb-1 align-items-center">
           <!-- Per Page -->
          <b-col cols="6" md="3" sm="6" xl="2" lg="2"
                class="d-flex align-items-center justify-content-start mb-md-0"
                v-if="visibleDepositStatusInput"
              >
                <label style="font-size:0.75rem;font-weight:400;">Show</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-100 compact_form_vue-select"
                />
                <label style="font-size:0.75rem;font-weight:400;">entries</label>
              </b-col>
              <!-- filter status -->
              <b-col cols="6" md="4" sm="6" xl="3" lg="3"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="statusOptions"
                  v-model="treeStatus"
                  valueFormat="object"
                  :limit="4"
                  clearable
                  :placeholder="$t('deposit.selections')"
                  v-if="visibleDepositStatusInput"
                  class="treeSelect compact_form_vue-treeselect"
                />
              </b-col >
              <!-- export && legend button -->
              <b-col cols="6" md="5" sm="6" xl="7" lg="7" class="d-flex justify-content-end align-items-center" v-if="visibleDepositStatusInput">
              <!-- legend -->
              <b-button
                    id="info-legend-button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="compact_nav_icon-text-button mr-1"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="14"
                      style="margin-bottom: 0.3rem;"
                    />
  
                    <span class="text-nowrap">{{
                      $t("deposit.buttons.legend_table")
                    }}</span>
                  </b-button>
                  <b-popover
                    target="info-legend-button"
                    placement="left"
                    triggers="click blur" 
                  >
                    <template #title>
                      {{ $t("deposit.legend_table.legend") }}
                    </template>
                    <b-table-simple hover small caption-top responsive bordered>
                      <b-tbody>
                        <!-- <b-tr>
                          <td style="width: 60%;">
                            {{ $t("deposit.legend_table.execution") }}
                          </td>
                          <td style="background-color: paleturquoise;"></td>
                        </b-tr> -->
                        <!-- <b-tr>
                          <td>
                            {{ $t("deposit.legend_table.verified") }}
                          </td>
                          <td style="background-color: springgreen;"></td>
                        </b-tr> -->
                        <!-- <b-tr>
                          <td>
                            {{ $t("deposit.legend_table.journal_created") }}
                          </td>
                          <td style="background-color: mediumpurple;"></td>
                        </b-tr> -->
                        <!-- <b-tr>
                          <td>
                            {{ $t("deposit.legend_table.expired_order") }}
                          </td>
                          <td style="background-color: silver;"></td>
                        </b-tr> -->
                        <!-- <b-tr>
                          <td>
                            {{ $t("deposit.legend_table.revers_initiated") }}
                          </td>
                          <td style="background-color: #FFFF99;"></td>
                        </b-tr> -->
                        <!-- <b-tr>
                          <td>
                            {{ $t("deposit.legend_table.reversed") }}
                          </td>
                          <td style="background-color: coral;"></td>
                        </b-tr> -->
                        <b-tr>
                          <td>
                            {{ $t("deposit.legend_table.cancelled") }}
                          </td>
                          <td style="background-color: #ffb8b8 !important;"></td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-popover>
                  <!-- export excell -->
                  <b-button
                    id="info-export-button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="compact_nav_icon-text-button"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                      style="margin-bottom: 0.3rem;"
                    />
  
                    <span class="text-nowrap">{{
                      $t("deposit.buttons.export")
                    }}</span>
                  </b-button>
                  <b-popover
                    target="info-export-button"
                    placement="right"
                    triggers="click blur" 
                  >
                    <template #title>
                      {{ $t("deposit.buttons.export") }}
                    </template>
  
                    <b-button size="sm" class="compact_nav_icon-text-button" @click="exportXlsx()">
                      <span class="text-nowrap">Save as .xlsx</span>
                    </b-button>
  
                    <b-button
                      size="sm"
                      class="compact_nav_icon-text-button mt-1"
                      @click="exportCsv()"
                    >
                      <span class="text-nowrap">Save as .csv</span>
                    </b-button>
                  </b-popover>
  
              </b-col>
          </b-row>
          <!-- list table -->
          
          <div
            @contextmenu.prevent="$refs.contextMenu.open"
            v-if="allDepositData.length > 0"
          >
            <div
              style="background-color:white;"
              :style="'height:' + (windowHeight - 350) + 'px'"
              id="table-container"
            >
              <b-table
                :sticky-header="true"
                :no-border-collapse="true"
                hover
                :bordered="true"
                ref="refdepositTable"
                class="compact_table"
                thead-class="animate__animated animate__fadeIn"
                :items="filteredTrades"
                :filter="filter"
                filter-debounce="250"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                selectable
                select-mode="single"
                
                :small="true"
                head-variant="dark"
                :fields="tableColumns"
                show-empty
                empty-text="No matching records found"
                :current-page="currentPage"
                :per-page="perPage"
                :tbody-tr-class="legendClass"
                caption-top
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortByDesc"
              >
          <!-- @row-clicked="onRowSelected" -->
  
              <!-- sort columns draggable -->
              <template slot="thead-top" slot-scope="data">
                <draggable v-model="tableColumns" tag="tr" id="draggableColumns" style="display: none;" class="animate__animated animate__fadeIn compact_draggable_table_columns">
                  <th v-for="header in tableColumns" :key="header.key" scope="col" style="white-space: nowrap;padding: 1rem;cursor:grab">{{ header.label }}</th>
                </draggable>
              </template>
  
                <!-- filter columns -->
                <template
                  slot="top-row"
                  slot-scope="{ fields }"
                  v-if="showColumnsFilter"
                >
                  <td
                    :class="field.key == 'ID' ? 'sticky-column' : ''"
                    v-for="field in tableColumns"
                    :key="field.key"
                  >
                    <v-select
                      v-if="field.key == 'ProductType'"
                      label="title"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                      :class="field.key + ' column_search_input compact_form_vue-select'"
                      :options="optProductTypes"
                      :clearable="false"
                      :reduce="(val) => val.value"
                    />
                    <v-select
                      v-else-if="field.key == 'GivenThrough'"
                      label="title"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                      :class="field.key + ' column_search_input compact_form_vue-select'"
                      :options="optOrderGivenThroughs"
                      :clearable="false"
                      :reduce="(val) => val.value"
                    />
                    <v-select
                      v-else-if="field.key == 'TakenVia'"
                      label="title"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                      :class="field.key + ' column_search_input compact_form_vue-select'"
                      :options="optOrderTakenVias"
                      :clearable="false"
                      :reduce="(val) => val.value"
                    />
                    <v-select
                      v-else-if="field.key == 'DayCountBasis'"
                      label="title"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                      :class="field.key + ' column_search_input compact_form_vue-select'"
                      :options="optDayCountBasises"
                      :clearable="false"
                      :reduce="(val) => val.value"
                    />
                    <input
                      v-else-if="field.key == 'ID'"
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                      :class="'ID_column_search_input compact_form-input'"
                      style="max-width: 75px;"
                    />
                    <input
                      v-else
                      v-model="filters[field.key]"
                      :placeholder="field.label"
                      :class="field.key + ' column_search_input compact_form-input'"
                    />
                    <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
                  </td>
                </template>

                <!-- Column: ID -->
                <template #cell(ID)="data">
                  <div class="d-flex justify-content-center align-items-center">
                    <b
                      ><span class="font-weight-bold text-center ">
                        <strong style="text-decoration:underline;">{{
                          data.item.ID ? data.item.ID : "-"
                        }}</strong>
                      </span>
                    </b>
                  </div>
                  <!-- trade controls -->
                  <div v-if="!showMultiplecheckboks && filteredTrades.length > 0" class="d-flex justify-content-center align-items-center compact_controls_button">
                    <b-button
                      :id="'trade-controls-' + data.item ? data.item.ID.toString() : ''"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-primary"
                      size="sm"
                      class="top-btns animate__animated animate__fadeIn"
                      @click="
                        showTradeControlsPopover(
                          'trade-controls-' + data.item ? data.item.ID.toString() : '',
                          data.item
                        )
                      "
                    >
                      <i class="fa-solid fa-bars"></i>
                    </b-button>
                    <b-popover
                      :target="'trade-controls-' + data.item ? data.item.ID.toString() : ''"
                      triggers="focus" 
                      variant="primary"
                    >
                      <template #title>
                        {{ $t("deposit.titles.trade_controls") }}
                      </template>
  
                      <!-- <b-button
                        v-if="$Can('verify_deposit') && seeVerifyButton"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-success"
                        :title="$t('deposit.buttons.verify_trade')"
                        size="sm"
                        class="top-btns mt-1"
                        @click="verifySelectedTrade(data.item)"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="CheckSquareIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("deposit.buttons.verify_trade") }}</span
                        >
                      </b-button> -->
  
                      <b-button
                        v-if="$Can('amend_deposit') && seeAmendButton"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-warning"
                        size="sm"
                        class="top-btns mt-1"
                        @click="amendUpdate(data.item)"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="EditIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("deposit.buttons.amend_trade") }}</span
                        >
                      </b-button>
  
                      <b-button
                        v-if="$Can('view_deposit')"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="top-btns mt-1"
                        @click="viewSelectedTrade(data.item)"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="EyeIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("deposit.buttons.view_trade") }}</span
                        >
                      </b-button>
  
                      <b-button
                        v-if="$Can('copy_deposit') && seeCopyButton"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="top-btns mt-1"
                        @click="copySelectedTrade(data.item)"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="CopyIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("deposit.buttons.copy_trade") }}</span
                        >
                      </b-button>
  
                      <b-button
                        v-if="$Can('history_deposit') && seeAmendDetailsButton"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="top-btns mt-1"
                        @click="getAmendDetails(data.item)"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="ListIcon"
                            style="margin-right: 0.3rem;"
                          />{{
                            $t("deposit.buttons.get_amend_details")
                          }}</span
                        >
                      </b-button>
  
                      <b-button
                        v-if="$Can('cancel_deposit') && seeCancelledButton"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-danger"
                        size="sm"
                        class="top-btns mt-1"
                        @click="cancelSelectedTrade(data.item)"
                        :disabled="data.item.Cancelled == 1"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="XSquareIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("deposit.buttons.cancel_trade") }}</span
                        >
                      </b-button>

                    </b-popover>
                  </div>
                  <!-- multiple checkboks trade controls -->
                  <div v-if="showMultiplecheckboks" class="d-flex justify-content-center align-items-center compact_controls_button">
                      <b-form-checkbox
                        plain
                        v-model="data.item.selectedTrade"
                        @change="
                          multiSelectRow(data.item.selectedTrade, data.item)
                        "
                        class="animate__animated animate__fadeIn"
                      >
                      </b-form-checkbox>
                    </div>
                </template>


                <!-- Column: TcUti -->
              <template #cell(TcUti)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcUti ? data.item.TcUti : "-"
                    }}</strong>
                  </span></b
                >
              </template>

                
                <!-- Column: Broken -->
              <template #cell(Broken)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Broken ? data.item.Broken : "-"
                    }}</strong>
                  </span></b
                >
              </template>
              <!-- Column: ProductType -->
              <template #cell(ProductType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ProductType ? data.item.ProductType : "-"
                    }}</strong>
                  </span></b
                >
              </template>
              <!-- Column: Counterparty -->
              <template #cell(Counterparty)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Counterparty ? data.item.Counterparty : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyAccount -->
              <template #cell(CounterpartyAccount)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CounterpartyAccount
                          ? data.item.CounterpartyAccount
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: Client -->
                <template #cell(Client)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.Client ? data.item.Client : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClientAccount -->
                <template #cell(ClientAcc)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientAccount ? data.item.ClientAccount : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: StartDate -->
              <template #cell(StartDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.StartDate
                        ? dateFormat(data.item.StartDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: EndDate -->
              <template #cell(EndDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.EndDate
                        ? dateFormat(data.item.EndDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Ccy -->
              <template #cell(Ccy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.Ccy ? data.item.Ccy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: DepositAmount -->
              <template #cell(DepositAmount)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.DepositAmount ? formatPrice(data.item.DepositAmount, 2) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: ClientRate -->
              <template #cell(ClientRate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientRate ? formatPrice(data.item.ClientRate, 4) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: ClientEndAmount -->
              <template #cell(ClientEndAmount)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientEndAmount ? formatPrice(data.item.ClientEndAmount, 2) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: ClientInterestTotal -->
              <template #cell(ClientInterestTotal)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientInterestTotal ? formatPrice(data.item.ClientInterestTotal, 2) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: DayCountBasis -->
              <template #cell(DayCountBasis)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.DayCountBasis ? data.item.DayCountBasis : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                 <!-- Column: CounterpartyRate -->
              <template #cell(CounterpartyRate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CounterpartyRate ? formatPrice(data.item.CounterpartyRate, 4) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: BankEndAmount -->
              <template #cell(BankEndAmount)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.BankEndAmount ? formatPrice(data.item.BankEndAmount, 2) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: BankInterestTotal -->
              <template #cell(BankInterestTotal)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.BankInterestTotal ? formatPrice(data.item.BankInterestTotal, 2) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: TcProfit -->
                <template #cell(TcProfit)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TcProfit
                          ? formatPrice(data.item.TcProfit, 2)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: BrokenDate -->
              <template #cell(BrokenDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.BrokenDate
                        ? dateFormat(data.item.BrokenDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: BrokenAccrued -->
              <template #cell(BrokenAccrued)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.BrokenAccrued ? data.item.BrokenAccrued : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: BrokenFee -->
              <template #cell(BrokenFee)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.BrokenFee ? data.item.BrokenFee : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: InstructionDate -->
              <template #cell(InstructionDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.InstructionDate
                        ? dateFormat(data.item.InstructionDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: InstructionTime -->
              <template #cell(InstructionTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.InstructionTime ? data.item.InstructionTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: Counterparty Trader -->
                <template #cell(CounterpartyTrader)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CounterpartyTrader
                          ? data.item.CounterpartyTrader
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: TcUser -->
                <template #cell(TcUser)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TcUser ? data.item.TcUser : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: TakenVia -->
                <template #cell(TakenVia)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TakenVia ? data.item.TakenVia : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: GivenThrough -->
                <template #cell(GivenThrough)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.GivenThrough
                          ? data.item.GivenThrough
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: TraderNote -->
                <template #cell(TraderNote)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TraderNote ? data.item.TraderNote : "-"
                      }}</strong>
                    </span></b
                  >
                </template>

                <!-- Column: UniqueLinkId -->
              <template #cell(UniqueLinkId)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                      }}</strong>
                    </span></b
                  >
                </template>


                <!-- Column: counterparty ref -->
                <template #cell(CounterpartyRef)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{ data.item.CounterpartyRef ? data.item.CounterpartyRef : "-" }}</strong>
                    </span></b
                  >
                </template>
                
                
                <!-- Column: SavedBy -->
                <template #cell(SavedBy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SavedBy ? data.item.SavedBy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SaveDate -->
                <template #cell(SaveDate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SaveDate != null
                          ? dateFormat(data.item.SaveDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SaveTime -->
                <template #cell(SaveTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SaveTime != null ? data.item.SaveTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendedBy -->
                <template #cell(AmendedBy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.AmendedBy ? data.item.AmendedBy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendmentDate -->
                <template #cell(AmendmentDate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.AmendmentDate != null
                          ? dateFormat(data.item.AmendmentDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendmentTime -->
                <template #cell(AmendmentTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.AmendmentTime != null
                          ? data.item.AmendmentTime
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendmentReason -->
                <template #cell(AmendmentReason)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.AmendmentReason
                          ? data.item.AmendmentReason
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Cancelled -->
                <template #cell(Cancelled)="data">
                  <b
                    ><span class="font-weight-bold text-white">
                      <strong>{{
                        data.item.Cancelled == 1 ? "Cancelled" : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelledBy -->
                <template #cell(CancelledBy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CancelledBy ? data.item.CancelledBy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelDate -->
                <template #cell(CancelDate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CancelDate != null
                          ? dateFormat(data.item.CancelDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelTime -->
                <template #cell(CancelTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CancelTime != null ? data.item.CancelTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelReason -->
                <template #cell(CancelReason)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CancelReason ? data.item.CancelReason : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                
  
                
  
                
              </b-table>
            </div>
          </div>
  
          <!-- mouse right click -->
          <vue-context ref="contextMenu" >
            <li >
              <b-link
                class="d-flex align-items-center"
                @click="optionRightClicked('showColumnsFilter')"
                :disabled="showOrderColumns"
              >
                <feather-icon
                  :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                  size="16"
                />
                <span class="ml-75">{{
                  !showColumnsFilter
                    ? "Show column filters"
                    : "Hide column filters"
                }}</span>
              </b-link>
            </li>
            <li >
              <b-link
                class="d-flex align-items-center"
                @click="optionRightClicked('showMultiplecheckboks')"
                :disabled="showOrderColumns"
              >
                <feather-icon
                  :icon="
                    !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                  "
                  size="16"
                />
                <span class="ml-75">{{
                  !showMultiplecheckboks
                    ? "Show multiple checkbox"
                    : "Hide multiple checkbox"
                }}</span>
              </b-link>
            </li>
            <li v-if="!showOrderColumns">
              <b-link
                class="d-flex align-items-center"
                @click="optionRightClicked('orderTableColumns')"
              >
              <i class="fa-solid fa-arrow-up-wide-short"></i>
                <span class="ml-75">{{ 'Sort columns'
                }}</span>
              </b-link>
            </li>
            <li v-if="selectedMultipleTrades.length > 0">
              <b-link
                class="d-flex align-items-center"
                @click="multipleChangeOprFunc('copyTrades')"
              >
                <feather-icon icon="CopyIcon" size="16" />
                <span class="ml-75">{{
                  $t("deposit.buttons.copy_trade")
                }}</span>
              </b-link>
            </li>
            <li
              v-if="multiSelectListStatusData.length > 0"
              v-for="(optStatus, index) in multiSelectListStatusData"
              :key="index"
            >
              <b-link
                class="d-flex align-items-center"
                @click="multipleChangeOprFunc(optStatus.key)"
              >
                <feather-icon
                  :icon="optStatus.icon"
                  size="16"
                  :stroke="optStatus.color"
                />
                <span class="ml-75">{{ optStatus.title }}</span>
              </b-link>
            </li>
          </vue-context>
          <div class="mt-1" v-if="allDepositData.length > 0">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted" style="font-size:0.8rem"
                  >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                  {{ $t("general_text.to") }} {{ dataMeta.to }}
                  {{ $t("general_text.of") }} {{ dataMeta.of }}
                  {{ $t("general_text.entries") }}</span
                >
              </b-col>
  
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0 compact_paginate"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
  
          <div v-if="allDepositData.length == 0">
            <b-alert variant="info" show>
              <div class="alert-body text-center">
                <span
                  ><strong>{{ $t("general_title.not_found_data") }}</strong>
                  <br />
                  {{
                    $t("deposit.messages.not_found_deposit_data")
                  }}</span
                >
              </div>
            </b-alert>
          </div>
        
        {{ /*search for trades modal*/ }}
        <SearchTrades
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="searchTrades"
          @searchedData="getSearchedData"
        />
  
        {{ /*View Selected Trade for trades modal*/ }}
        <ViewSelectedTrade
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="ViewSelectedTrade"
        />
  
        {{ /*Enter New Trade for trades modal*/ }}
        <EnterNewTrade
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="refEnterNewTrade"
          @createddeposit="createddeposit"
        />
        {{ /*Verify selected Trade for trades modal*/ }}
        <!-- <VerifyTrade
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="refVerifyTrade"
          @verified="verifiedTrade"
        /> -->
        {{ /*Amend selected Trade for trades modal*/ }}
        <AmendSelectedTrade
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="refAmendTrade"
          @updatedData="getUpdatedAmendData"
        />
        {{ /*Copy selected Trade for trades modal*/ }}
        <CopySelectedTrade
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="refCopyTrade"
          @copieddeposit="copieddeposit"
        />
      </b-card>
    </b-overlay>
      {{/** view modal failed multi select process*/}}
      <b-modal
        id="multiSelectProcessModal"
        v-model="showMultiSelectProcessModal"
        :title="$t('multiCheckBoxTitles.failes_process_title')"
        :ok-title="$t('general_buttons.close')"
        @ok="okMultiSelectProcessModal"
        @hidden="okMultiSelectProcessModal"
        size="lg"
        :body-bg-variant="'light'"
        :header-bg-variant="'light'"
        ok-only
        ok-variant="primary"
      >
        <b-container>
          <b-form>
            <b-row class="mt-0">
              <b-col
                cols="12"
                xl="12"
                md="12"
                sm="12"
                class="bodyRow mt-1"
                style="padding: 0.5rem 0;"
              >
                <b-table
                  striped
                  hover
                  ref="refMultiSelectProcessModal"
                  class="position-relative"
                  :items="multiSelectProcessModalTableData"
                  selectable
                  select-mode="single"
                  responsive
                  head-variant="light"
                  :fields="multiSelectProcessModalTableColumns"
                  show-empty
                  :empty-text="
                    $t('deposit.general_messages.no_matching_recors_found')
                  "
                >
                  <template #cell(ID)="data">
                    <strong>{{ data.item.ID }}</strong>
                  </template>
  
                  <template #cell(Tcuti)="data">
                    <strong>{{ data.item.Tcuti }}</strong>
                  </template>
  
                  <template #cell(reason)="data">
                    <strong>{{ data.item.reason }}</strong>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
        {{/****END*** view modal failed multi select process*/}}
      </b-modal>
    </div>
  </template>
  
  <script>
  import {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    VBModal,
    BTabs,
    BTab,
    BCardText,
    BIcon,
    BPopover,
    VBTooltip,
  } from "bootstrap-vue";
  import store from "@/store";
  import { onUnmounted } from "@vue/composition-api";
  import depositStoreModule from "./depositStoreModule";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, alphaNum, email } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import axios from "axios";
  import axiosIns from "@/libs/axios";
  import router from "@/router";
  import moment from "moment";
  import "animate.css";
  import _ from "lodash";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import { mapState, mapGetters, mapActions } from "vuex";
  import SearchTrades from "./SearchTrades.vue";
  import ViewSelectedTrade from "./ViewSelectedTrade.vue";
  import EnterNewTrade from "./EnterNewTrade.vue";
  import AmendSelectedTrade from "./AmendSelectedTrade.vue";
  import CopySelectedTrade from "./CopySelectedTrade.vue";
  import VueHorizontal from "vue-horizontal";
  import VueContext from "vue-context";
  import { debounce } from "lodash";
  import draggable from 'vuedraggable'
  
  export default {
    components: {
      BOverlay,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BTabs,
      BTab,
      BCardText,
      BDropdown,
      BDropdownItem,
      BPagination,
      BFormRating,
      BIcon,
      BPopover,
      vSelect,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      flatPickr,
      SearchTrades,
      ViewSelectedTrade,
      EnterNewTrade,
      AmendSelectedTrade,
      CopySelectedTrade,
      Treeselect,
      VueHorizontal,
      VueContext,
      draggable
    },
  
    directives: {
      Ripple,
      "b-modal": VBModal,
      "b-tooltip": VBTooltip,
    },
  
    setup() {
      const deposit_APP_STORE_MODULE_NAME = "deposit";
      // Register module
      if (!store.hasModule(deposit_APP_STORE_MODULE_NAME))
        store.registerModule(
          deposit_APP_STORE_MODULE_NAME,
          depositStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(deposit_APP_STORE_MODULE_NAME))
          store.unregisterModule(deposit_APP_STORE_MODULE_NAME);
      });
    },
  
    mounted() {
      this.getOrderdepositColumns();
  
      //option status
      let treeOptionsStatus = [
        {
          id: 1000000,
          label: this.$t("deposit.status"),
          children: [],
        },
      ];
      this.depositStatusOptions.forEach((element) => {
        let setStatus = {
          id: element.text,
          label: element.text,
          value: element.text,
          is: "status",
        };
        treeOptionsStatus[0].children.push(setStatus);
      });
  
      this.statusOptions = treeOptionsStatus;
      //************************** */
  
      
  
      //get last 7 days trades
      this.getLast7DaysTrades();
      this.getAllAccounts();

    //   this.getArrangedepositOrderTakenGivenThrough();
  
      this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
      window.addEventListener("resize", this.debouncedGetWindowHeight);
  
  
      
    },
  
    created() {
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.debouncedGetWindowHeight);
    },
  
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  
    data() {
      const depositStatusOptions = [
        // { text: "Execution" },
        // { text: "Expired Order" },
        // { text: "Verified" },
        { text: "Cancelled" },
      ];
  
      const depositTableColumns = [
        // { key: "show_details", label: null },
        {
          stickyColumn: true,
          key: "ID",
          label: "ID",
          // thClass: "text-left",
          tdClass: "text-center ID_background",
          thClass: "sticky-column",
          thStyle: "width: 50px",
          sortable: true,
          class: "text-start table-padding sticky-column",
          variant: "primary",
        },
        {
        key: "TcUti",
        label: this.$t("equity_bond.table.TcUti"),
        class: "text-start table-padding",
        },
        {
        key: "ProductType",
        label: this.$t("equity_bond.table.ProductType"),
        class: "text-start table-padding",
        },
        {
          key: "Broken",
          label: this.$t("deposit.table.Broken"),
          class: "text-start table-padding",
        },
        {
          key: "Counterparty",
          label: this.$t("deposit.table.Counterparty"),
          class: "text-start table-padding text-nowrap",
          sortable: true,
        },
        {
          key: "CounterpartyAccount",
          label: this.$t("deposit.table.CounterpartyAccount"),
          class: "text-start table-padding",
        },
        {
          key: "Client",
          label: this.$t("deposit.table.Client"),
          class: "text-start table-padding",
          sortable: true,
        },
        {
          key: "ClientAccount",
          label: this.$t("deposit.table.ClientAccount"),
          class: "text-start table-padding",
        },
        {
        key: "StartDate",
        label: this.$t("equity_bond.table.StartDate"),
        class: "text-start table-padding text-nowrap",
        },
        {
        key: "EndDate",
        label: this.$t("equity_bond.table.EndDate"),
        class: "text-start table-padding text-nowrap",
        },
        {
          key: "Ccy",
          label: this.$t("deposit.table.Ccy"),
          class: "text-start table-padding",
        },
        {
          key: "DepositAmount",
          label: this.$t("deposit.table.DepositAmount"),
          class: "text-start table-padding",
        },
        {
          key: "ClientRate",
          label: this.$t("deposit.table.ClientRate"),
          class: "text-start table-padding",
        },
        {
          key: "ClientEndAmount",
          label: this.$t("deposit.table.ClientEndAmount"),
          class: "text-start table-padding",
        },
        {
          key: "ClientInterestTotal",
          label: this.$t("deposit.table.ClientInterestTotal"),
          class: "text-start table-padding",
        },
        {
          key: "DayCountBasis",
          label: this.$t("deposit.table.DayCountBasis"),
          class: "text-start table-padding",
        },
        {
          key: "CounterpartyRate",
          label: this.$t("deposit.table.CounterpartyRate"),
          class: "text-start table-padding",
        },
        {
          key: "BankEndAmount",
          label: this.$t("deposit.table.BankEndAmount"),
          class: "text-start table-padding",
        },
        {
          key: "BankInterestTotal",
          label: this.$t("deposit.table.BankInterestTotal"),
          class: "text-start table-padding",
        },
        {
          key: "TcProfit",
          label: this.$t("deposit.table.TcProfit"),
          class: "text-start table-padding",
        },
        {
          key: "BrokenDate",
          label: this.$t("deposit.table.BrokenDate"),
          class: "text-start table-padding",
        },
        {
          key: "BrokenAccrued",
          label: this.$t("deposit.table.BrokenAccrued"),
          class: "text-start table-padding",
        },
        {
          key: "BrokenFee",
          label: this.$t("deposit.table.BrokenFee"),
          class: "text-start table-padding",
        },
        {
          key: "InstructionDate",
          label: this.$t("deposit.table.InstructionDate"),
          class: "text-start table-padding",
        },
        {
          key: "InstructionTime",
          label: this.$t("deposit.table.InstructionTime"),
          class: "text-start table-padding",
        },
        {
          key: "CounterpartyTrader",
          label: this.$t("deposit.table.CounterpartyTrader"),
          class: "text-start table-padding",
        },
        
        {
          key: "TcUser",
          label: this.$t("deposit.table.TcUser"),
          class: "text-start table-padding",
        },

        {
          key: "TakenVia",
          label: this.$t("deposit.table.TakenVia"),
          class: "text-start table-padding",
        },
        {
          key: "GivenThrough",
          label: this.$t("deposit.table.GivenThrough"),
          class: "text-start table-padding",
        },
        {
          key: "TraderNote",
          label: this.$t("deposit.table.note"),
          class: "text-start table-padding",
        },
        
        {
          key: "UniqueLinkId",
          label: this.$t("deposit.table.UniqueLinkId"),
          class: "text-start table-padding",
        },
        
        {
          key: "CounterpartyRef",
          label: this.$t("deposit.table.CounterpartyRef"),
          class: "text-start table-padding",
        },

        {
          key: "SavedBy",
          label: this.$t("deposit.table.saved_by"),
          class: "text-start table-padding",
        },
        {
          key: "SaveDate",
          label: this.$t("deposit.table.save_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "SaveTime",
          label: this.$t("deposit.table.save_time"),
          class: "text-start table-padding",
        },
        {
          key: "AmendedBy",
          label: this.$t("deposit.table.amended_by"),
          class: "text-start table-padding",
        },
        {
          key: "AmendmentDate",
          label: this.$t("deposit.table.amendment_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "AmendmentTime",
          label: this.$t("deposit.table.amendment_time"),
          class: "text-start table-padding",
        },
        {
          key: "AmendmentReason",
          label: this.$t("deposit.table.amendment_reason"),
          class: "text-start table-padding",
        },
        {
          key: "Cancelled",
          label: this.$t("deposit.table.cancelled"),
          class: "text-start table-padding",
        },
        {
          key: "CancelledBy",
          label: this.$t("deposit.table.cancelled_by"),
          class: "text-start table-padding",
        },
        {
          key: "CancelDate",
          label: this.$t("deposit.table.canceled_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "CancelTime",
          label: this.$t("deposit.table.cancelled_time"),
          class: "text-start table-padding",
        },
        {
          key: "CancelReason",
          label: this.$t("deposit.table.cancelled_reason"),
          class: "text-start table-padding",
        },
        
        ];
  
      return {
        multiSelectProcessModalTableColumns: [
          {
            key: "ID",
            label: this.$t(
              "multiCheckBoxTitles.failed_proceses_table_columns.id"
            ),
            class: "text-center",
            thStyle: "width: 20%",
          },
          {
            key: "Tcuti",
            label: this.$t(
              "multiCheckBoxTitles.failed_proceses_table_columns.tcUti"
            ),
            sortable: false,
            class: "text-start",
            thStyle: "width: 30%",
          },
          {
            key: "reason",
            label: this.$t(
              "multiCheckBoxTitles.failed_proceses_table_columns.reason"
            ),
            sortable: false,
            class: "text-start",
            thStyle: "width: 50%",
          },
        ],
        userData: JSON.parse(localStorage.getItem("userData")),
        windowHeight: window.innerHeight,
        allDepositData: [],
        alldepositNotFilterData: [],
        visibleDepositStatusInput: false,
        optProductTypes: [
          { title: "All", value: "" },
          { title: "TIME DEPOSIT", value: "TIME DEPOSIT" },
          { title: "COLLATERAL DEPOSIT", value: "COLLATERAL DEPOSIT" },
          { title: "CLIENT POOL", value: "CLIENT POOL" },
          { title: "CLIENT POOL COLLATERAL", value: "CLIENT POOL COLLATERAL" },
        ],
        optDayCountBasises:[
          { title: "All", value: "" },
          { title: "365", value: "365" },
          { title: "360", value: "360" },
        ],
        optOrderTakenVias: [],
        optOrderGivenThroughs: [],
        sortByDesc: true,
        sortBy: "ID",
        filterOn: [
          "TcUser",
          "Counterparty",
          "Client",
          "GivenThrough",
          "TakenVia",
        ],
        tableColumns: null,
        tableOrjinalColumns: null,
        depositTableColumns:depositTableColumns,
        filters: {
          ProductType: "",
          TcUti: "",
          Counterparty: "",
        },
        perPage: localStorage.getItem("depositTradesShow") ?? 25,
        perPage: 25,
        currentPage: 1,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100, 500],
        filter: null,
        loading: false,
        filterDepositDate: null,
        filterInstructionDate: null,
        canceledReasonText: null,
        statusOptions: [],
        tableColumnsOptions: [],
        depositStatusOptions: depositStatusOptions,
        treeStatus: null,
        treeTablecolumns: [],
        depositRangeDate: null,
        filterDepositDateStart: null,
        filterDepositDateEnd: null,
        instructionRangeDate: null,
        filterInstructionDateStart: null,
        filterInstructionDateEnd: null,
        dateConfig: {
          allowInput:true,
          dateFormat: "d-m-Y",
          mode: "range",
        },
  
        optClients: [],
        optCounterParties: [],
        searchedClients: [],
        searchedCounterparties: [],
        optCostCenters: [],
        showColumnsFilter: false,
        showMultiplecheckboks: false,
        showOrderColumns:false,
        orderColumnsOverlay:false,
        selectedMultipleTrades: [],
        multiSelectListStatusData: [],
        showMultiSelectProcessModal: false,
        multiSelectProcessModalTableData: [],
        seeAmendDetailsButton: false,
        seeAmendButton : false,
        seeCopyButton : false,
        seeVerifyButton: false,
        seeJournalButton: false,
        seeJorurnalEntriesButton: false,
        seeReversalInitiatedButton: false,
        seeUndoInitiatedButton: false,
        seeReversedButton: false,
        seeCancelledButton: false
      };
    },
    computed: {
      ...mapState({
        lastSearchData: (state) => state.runLastdepositSearchData,
        userColumnsData: (state) => state.usersOrderColumnsdepositData,
      }),
      user: {
        get() {
          return this.$store.getters["authUser"];
        },
        set(newValue) {
          return this.$store.dispatch("setUser", newValue);
        },
      },
      ...mapGetters(["getUserOrderColumnsDeposit"]),
  
      filteredTrades() {
        const filtered = this.allDepositData.filter((item) => {
          return Object.keys(this.filters).every((key) =>
            String(item[key]).includes(this.filters[key])
          );
        });
        return filtered.length > 0
          ? filtered
          : filtered
      },
      dataMeta() {
        return {
          from:
            this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
          to:
            this.perPage * (this.currentPage - 1) + Number(this.perPage) <
            this.totalRows
              ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
              : this.totalRows,
          of: this.totalRows,
        };
      },
    },
    methods: {
      ...mapActions(["saveOrderColumnsdeposit","getOrderColumnsDeposit"]),
      getWindowHeight() {
        this.windowHeight = window.innerHeight;
  
      
        this.updateStickyHeaderHeight()
  
        },
  
        updateStickyHeaderHeight() {
          this.$nextTick(() => {
                const headerElements = document.getElementsByClassName("b-table-sticky-header");
                if (headerElements.length > 0) {
                  const newHeight = window.innerHeight - 550;
                  this.stickyHeaderStyle = `height: ${newHeight}px`;
                }
        });
      },
  
      showTradeControlsPopover(targetTrade, trade) {
        if (trade) {
          // set default
          this.seeAmendDetailsButton = false;
          this.seeVerifyButton = false;
          this.seeAmendButton = false
          this.seeCopyButton = false
          this.seeJournalButton = false;
          this.seeJorurnalEntriesButton = false;
          this.seeReversalInitiatedButton = false;
          this.seeUndoInitiatedButton = false;
          this.seeReversedButton = false;
          this.seeCancelledButton = false;
  
          // set suitable flow buttons
  
          // see amend details
          if (
            trade.AmendedBy != null &&
            trade.AmendmentDate != null &&
            trade.AmendmentTime != null &&
            trade.Cancelled == 0
          ) {
            this.seeAmendDetailsButton = true;
          }
  
          // see amend trade button
            
          if(trade.Cancelled == 0){
              this.seeAmendButton = true
            }
            // see copy trade button
            if(trade.Cancelled == 0){
              this.seeCopyButton = true
            }
  
          // see verify
          if (trade.Verified == 0 && trade.Cancelled == 0) {
            this.seeVerifyButton = true;
          }
          // see journal
          if (
            trade.EntryType == "Execution" &&
            trade.Verified == 1 &&
            trade.JournalCreated == 0 && 
            trade.Cancelled == 0
          ) {
            this.seeJournalButton = true;
          }
  
          // see trade journalEntries
          if (
            trade.EntryType == "Execution" &&
            trade.Verified == 1 &&
            trade.JournalCreated == 1 && 
            trade.Cancelled == 0
          ) {
            this.seeJorurnalEntriesButton = true;
          }
  
          // see revers initiated
          if (
            trade.EntryType == "Execution" &&
            trade.Verified == 1 &&
            trade.JournalCreated == 1 &&
            trade.ReversalInitiated == 0 && 
            trade.Cancelled == 0
          ) {
            this.seeReversalInitiatedButton = true;
          }
  
          // see undo initiated
          if (
            trade.EntryType == "Execution" &&
            trade.Verified == 1 &&
            trade.JournalCreated == 1 &&
            trade.ReversalInitiated == 1 &&
            trade.Reversed == 0 && 
            trade.Cancelled == 0
          ) {
            this.seeUndoInitiatedButton = true;
          }
  
          // see reversed
          if (
            trade.EntryType == "Execution" &&
            trade.Verified == 1 &&
            trade.JournalCreated == 1 &&
            trade.ReversalInitiated == 1 &&
            trade.Reversed == 0 && 
            trade.Cancelled == 0
          ) {
            this.seeReversedButton = true;
          }
  
          // if cancelled trade it can not do
          if (
            trade.CancelDate == null &&
            trade.Cancelled == 0 &&
            trade.CancelTime == null
          ) {
            this.seeCancelledButton = true;
          }

          
        }
  
        this.$root.$emit("bv::show::popover", targetTrade);
      },
      optionRightClicked(click) {
        if (click == "showColumnsFilter") {
          this.showColumnsFilter = !this.showColumnsFilter;
        }
        if (click == "showMultiplecheckboks") {
          this.showMultiplecheckboks = !this.showMultiplecheckboks;
          this.multiSelectListStatusData = [];
          this.selectedMultipleTrades = [];
  
          // this.tableColumns[1].class = this.showMultiplecheckboks
          //   ? "text-center"
          //   : "text-center d-none";
          this.multiSelectListStatusData = [];
          _.mapValues(this.allDepositData, (v) => (v.selectedTrade = false));
        }
        if(click == "orderTableColumns"){
          this.showOrderColumns = !this.showOrderColumns
          this.orderColumnsOverlay = true
          // hide main columns
          this.$refs.refdepositTable.$el.classList.add("hide-rows")
  
          // visible draggable order columns
          var draggableColumns = document.getElementById("draggableColumns")
          draggableColumns.style.display = ""
        }
      },
      getOrderdepositColumns(){
  
        this.getOrderColumnsDeposit().then((res) => {
          // get order user columns
          if (this.userColumnsData.find((data) => data.user == this.user.name)) {
            let orderedColumnsData = this.getUserOrderColumnsdeposit
            this.tableColumns = orderedColumnsData
            this.tableOrjinalColumns = this.depositTableColumns
            
          } else {
          
            this.tableColumns = this.depositTableColumns
            this.tableOrjinalColumns = this.depositTableColumns
          }
          
          //******************************* */
  
            // tree columns options
            let treeColumnsOption = [
          {
            id: 1000000,
            label: this.$t("deposit.columns"),
            children: [],
          },
        ];
  
        this.tableOrjinalColumns.forEach((element, index) => {
          if (element.key != "ID") {
            var setColumn = {
              id: index,
              label: element.key,
              value: element.key,
              is: "column",
            };
            treeColumnsOption[0].children.push(setColumn);
  
            if(this.tableColumns.find((column) => column.key === element.key)){
              this.treeTablecolumns.push(setColumn);
            }
          }
          
        });
        this.tableColumnsOptions = treeColumnsOption;
        
        //this.treeTablecolumns = treeColumnsOption[0].children;
        //******************* */
  
        }).catch((error) => {
          this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
          console.log(error);
        });
      },
      onSaveOrderColumns(){
        if(this.tableColumns && this.tableColumns.length > 0){
            this.saveOrderColumnsdeposit(this.tableColumns).then((res) => {
              // visible main columns
            this.$refs.refdepositTable.$el.classList.remove("hide-rows");
            // hide draggable order columns
            var draggableColumns = document.getElementById("draggableColumns");
            draggableColumns.style.display = "none";
            this.showOrderColumns = false;
            this.orderColumnsOverlay = false
            this.successOrderColumnsMessage()
            }).catch((error) => {
              console.log(error);
            });
        } else {
          this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
        }
      },
      onCancelOrderColumn(){
        // visible main columns
        this.$refs.refdepositTable.$el.classList.remove("hide-rows");
        // hide draggable order columns
        var draggableColumns = document.getElementById("draggableColumns");
        draggableColumns.style.display = "none";
        this.showOrderColumns = false;
        this.orderColumnsOverlay = false;
      },
      selectTreeColumn(node){
  
        if(this.tableOrjinalColumns.find((c) => c.key === node.value)){
          let columnIndex = this.tableOrjinalColumns.findIndex((i) => i.key === node.value)
          let columnData = this.tableOrjinalColumns.find((c) => c.key === node.value)
          this.tableColumns.splice(columnIndex,0,columnData)
        }        
        
      },
      deSelectTreeColumn(node){
        if(this.tableColumns.find((c) => c.key === node.value)){
          let columnIndex = this.tableColumns.findIndex((i) => i.key === node.value)
          this.tableColumns.splice(columnIndex,1)
        }  
      },
      multiSelectRow(check, trade) {
        if (check) {
          //add right click check true trades
          this.multiSelectListStatusData = [];
          this.selectedMultipleTrades.push(trade);
        } else {
          // remove false check trades
          let clonedTrades = JSON.parse(
            JSON.stringify(this.selectedMultipleTrades)
          );
          _.remove(clonedTrades, {
            ID: trade.ID,
          });
  
          this.selectedMultipleTrades = clonedTrades;
        }
      },
      multipleChangeOprFunc(status) {
        let tradesCount = null;
        let trades = [];
  
        if (status == "Verify") {
          tradesCount = _.filter(this.selectedMultipleTrades, {
            EntryType: "Execution",
            Verified: 0,
            Cancelled: 0,
          }).length;
  
          trades = _.filter(this.selectedMultipleTrades, {
            EntryType: "Execution",
            Verified: 0,
            Cancelled: 0,
          });
        }
       
  
        if (status == "Cancel") {
          tradesCount = _.filter(this.selectedMultipleTrades, {
            Cancelled: 0,
          }).length;
  
          trades = _.filter(this.selectedMultipleTrades, {
            Cancelled: 0,
          });
        }
  
        if (status == "copyTrades") {
          tradesCount = _.filter(this.selectedMultipleTrades, {
            Cancelled: 0,
          }).length;
  
          trades = _.filter(this.selectedMultipleTrades, {
            Cancelled: 0,
          });
        }
  
        if (trades.length > 0 && status) {
          if (status == "copyTrades") {
            this.tradeMultipleSelectCopyOpr(tradesCount, trades);
          } else {
            this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
          }
        }
      },
  
      exportXlsx() {
        let columns = [];
        this.tableColumns.forEach((element) => {
          columns.push(element.key);
        });
  
        let datas = this.filteredTrades.map((item) => {
          const orderedItem = {};
          columns.forEach((column) => {
            
            if (column in item) {
              if (column == "Cancelled") {
                if (item[column] == 1) {
                  orderedItem[column] = "Cancelled";
                } else {
                  orderedItem[column] = "Not Cancelled";
                }
              } else {
                orderedItem[column] = item[column];
              }
            }
          });
          return orderedItem;
        });
  
        let columnNames = [];
  
        this.tableColumns.forEach((element) => {
          columnNames.push(element.label);
        });
  
        store
          .dispatch("deposit/exportXlsx", {
            data: datas,
            headings: columnNames,
          })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/octet-stream",
            });
  
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "deposit.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      exportCsv() {
        let columns = [];
        this.tableColumns.forEach((element) => {
          columns.push(element.key);
        });
  
        let datas = this.filteredTrades.map((item) => {
          const orderedItem = {};
          columns.forEach((column) => {
            if (column == "EntryType2") {
              orderedItem[column] = "Main Entry";
            }
            if (column in item) {
              if (column == "Cancelled") {
                if (item[column] == 1) {
                  orderedItem[column] = "Cancelled";
                } else {
                  orderedItem[column] = "Not Cancelled";
                }
              } else {
                orderedItem[column] = item[column];
              }
            }
          });
          return orderedItem;
        });
  
        let columnNames = [];
  
        this.tableColumns.forEach((element) => {
          columnNames.push(element.label);
        });
  
        store
          .dispatch("deposit/exportCsv", {
            data: datas,
            headings: columnNames,
          })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/octet-stream",
            });
  
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "deposit.csv");
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      getAllAccounts() {
        store
          .dispatch("deposit/getAccountCostCenter", { type: "Deposit" })
          .then((res) => {
            this.optClients = [];
            this.optCounterParties = [];
            this.searchedClients = res.data.clients;
            this.searchedCounterparties = res.data.clients;
            this.optCostCenters = [];
            this.optCostCenters = res.data.cost_centers;
            this.$refs.refEnterNewTrade.tradeForm.costCenter =
              res.data.default_cost_center;
            this.$refs.refAmendTrade.tradeForm.costCenter =
              res.data.default_cost_center;
            this.$refs.refEnterNewTrade.taxes = res.data.taxes;
            this.$refs.refAmendTrade.taxes = res.data.taxes;
            this.$refs.refCopyTrade.taxes = res.data.taxes;
            this.$refs.refEnterNewTrade.defaultTaxRate =
              res.data.default_tax_rate;
            this.$refs.refAmendTrade.defaultTaxRate = res.data.default_tax_rate;
            this.$refs.refCopyTrade.defaultTaxRate = res.data.default_tax_rate;
            this.$refs.refEnterNewTrade.commissions = res.data.commissions;
            this.$refs.refAmendTrade.commissions = res.data.commissions;
            this.$refs.refCopyTrade.commissions = res.data.commissions;
  
            if (res.data.clients.length > 0) {
              res.data.clients.forEach((data) => {
                this.optClients.push(data.AccountName);
              });
  
              res.data.clients.forEach((data) => {
                this.optCounterParties.push(data.AccountName);
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      getLast7DaysTrades() {
        this.$checkAccessRight("deposit", "Search").then((res) => {
          if (res.data.valid) {
            this.loading = true;
            store
              .dispatch("deposit/getLast7DaysTradesDeposits")
              .then((res) => {
                this.allDepositData = [];
                if (res.data.length > 0) {
                  this.visibleDepositStatusInput = true;
                } else {
                  this.visibleDepositStatusInput = false;
                }
                this.totalRows = res.data.length;
                this.allDepositData = res.data;
                this.alldepositNotFilterData = res.data;
                this.loading = false;
                
              })
              .catch((error) => {
                this.databaseErrorMessage();
                console.log(error);
              });
          } else {
            this.notAuthToastMessage();
          }
        });
      },
      getArrangedepositOrderTakenGivenThrough() {
        store
          .dispatch(
            "deposit/fetchArrangedepositOrderTakenGivenThrough",
            this.user
          )
          .then((res) => {
            if (
              res.data.orderGivenThroughs.length > 0 ||
              res.data.orderTakenVias.length > 0
            ) {
              this.optOrderGivenThroughs.push({ title: "All", value: "" });
  
              if (res.data.orderGivenThroughs.length > 0) {
                res.data.orderGivenThroughs.forEach((data) => {
                  this.optOrderGivenThroughs.push({ title: data, value: data });
                });
              }
              this.optOrderTakenVias.push({ title: "All", value: "" });
  
              if (res.data.orderTakenVias.length > 0) {
                res.data.orderTakenVias.forEach((data) => {
                  this.optOrderTakenVias.push({ title: data, value: data });
                });
              }
            } 
            // else {
            //   this.errorMessage(
            //     this.$t("deposit.messages.creating_message")
            //   );
            // }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      
      cancelSelectedTrade(item) {
        this.$checkAccessRight("deposit", "Cancel").then((res) => {
          if (res.data.valid) {
            if (item.Cancelled == 0) {
                  if (item.ID != 0) {
                    this.tradeCancelReasonText().then((res) => {
                      if (res) {
                        store
                          .dispatch("deposit/getCanceledDepositById", {
                            auth: this.user,
                            tradeId: item.ID,
                            reason: this.canceledReasonText,
                            trade: item,
                          })
                          .then((res) => {
                            if (res.data.info == "Pass") {
                              this.tradeCancelledMessage();
                              this.runLastSearch();
                              this.canceledReasonText = null;
                            } else {
                              this.tradeCancelledErrorMessage(res.data.message);
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        this.cancelOpearionNotDoneMessage();
                      }
                    });
                  } else {
                    this.tradeIDDoesntZeroMessage();
                  }
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      },
      
      async tradeCancelReasonText() {
        const self = this;
        const { value: reason } = await this.$swal({
          title: self.$t("deposit.reason.cancel_reason"),
          text: self.$t("deposit.reason.please_enter_cancel_reason"),
          input: "textarea",
          inputPlaceholder: self.$t(
            "deposit.reason.please_enter_cancel_reason"
          ),
          //icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          inputAttributes: {
            style: "margin-top:5px!important",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("deposit.reason.submit"),
          cancelButtonText: self.$t("deposit.reason.Cancel"),
          customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-warning",
            text: "mb-1",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          inputValidator: function(reason) {
            // resons your input
            return new Promise(function(resolve, reject) {
              if (reason != null && reason != null && reason.length > 3) {
                self.canceledReasonText = reason;
                resolve();
              } else {
                resolve(self.$t("deposit.reason.you_need_write_reason"));
                self.validReasonTextMessage();
                //reject("Please enter a valid text");
              }
            });
          },
        });
  
        if (reason) {
          return new Promise(function(resolve, reject) {
            resolve(true);
          });
        }
      },
      tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
        const self = this;
        this.$swal({
          title: self.$t("multiCheckBoxTitles.change_status_title"),
          text:
            self.$t("multiCheckBoxTitles.trades_count_message_1") +
            tradesCount +
            self.$t("multiCheckBoxTitles.trades_count_message_2"),
          input:
            status == "Revers Inititated" || status == "Cancel" ? "textarea" : "",
          inputPlaceholder: self.$t(
            "deposit.reason.please_enter_reversal_reason"
          ),
          //icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          inputAttributes: {
            style: "margin-top:5px!important",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
          cancelButtonText: self.$t("general_buttons.cancel"),
          customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-warning",
            text: "mb-1",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          inputValidator: function(reason) {
            // resons your input
            return new Promise(function(resolve, reject) {
              if (reason != "" && reason != null && reason.length > 3) {
                resolve();
              } else {
                resolve(
                  self.$t("deposit.reason.you_need_write_reason")
                );
                self.validReasonTextMessage();
                //reject("Please enter a valid text");
              }
            });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.loading = true;
  
           
  
            store
              .dispatch("deposit/tradeMultipleChangeDepositStatusOpr", {
                auth: self.user,
                trades: trades,
                status: status,
                reason: result.value ? result.value : null,
              })
              .then((res) => {
                self.loading = false;
                if (res.data.failedProcesses.length > 0) {
                  self.multiSelectListStatusData = [];
                  self.selectedMultipleTrades = [];
                  self.multiSelectAllProcesesWarningMessage();
                  self.showMultiSelectProcessModal = true;
                  self.multiSelectProcessModalTableData =
                    res.data.failedProcesses;
                } else {
                  self.multiSelectListStatusData = [];
                  self.selectedMultipleTrades = [];
                  self.multiSelectAllProcesesSuccessMessage();
                  _.mapValues(
                    this.allDepositData,
                    (v) => (v.selectedTrade = false)
                  );
                  if (trades.length == 1 && status == "Revers Inititated") {
                    let val = {
                      info: true,
                      tradeId: trades[0].ID,
                      tcUti: trades[0].tcUti,
                      reason: result.value ? result.value : null,
                    };
                    this.reversaledTrade(val);
                  }
  
                  self.okMultiSelectProcessModal();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      },
  
      tradeMultipleSelectCopyOpr(tradesCount, trades) {
        const self = this;
        this.$swal({
          title: self.$t("multiCheckBoxTitles.change_copy_title"),
          text: tradesCount + self.$t("multiCheckBoxTitles.trades_copy_message"),
          //icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          inputAttributes: {
            style: "margin-top:5px!important",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
          cancelButtonText: self.$t("general_buttons.cancel"),
          customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-warning",
            text: "mb-1",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.loading = true;
  
            // set trade data
            if (trades.length > 0) {
              var setTradesData = [];
              trades.forEach((trade) => {
                setTradesData.push(self.setTradeData(trade));
              });
            }
            trades = setTradesData;
            store
              .dispatch("deposit/tradeMultipleSelectDepositCopyOpr", {
                auth: self.user,
                trades: trades,
              })
              .then((res) => {
                self.loading = false;
                self.selectedMultipleTrades = [];
  
                if (res.data.failedCopyOpr.length > 0) {
                  self.multiSelectCopyWarningMessage();
                  self.okMultiSelectProcessModal();
                  self.showMultiSelectProcessModal = true;
                  self.multiSelectProcessModalTableData = res.data.failedCopyOpr;
                } else if (res.data.info == "not_found") {
                  this.wrongToastMessage(res.data.message);
                } else {
                  self.multiSelectAllTradesCopySuccessMessage();
                  self.okMultiSelectProcessModal();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      },
  
      okMultiSelectProcessModal() {
        this.showMultiSelectProcessModal = false;
        this.multiSelectProcessModalTableData = [];
        _.mapValues(this.allDepositData, (v) => (v.selectedTrade = false));
  
          this.getLast7DaysTrades();
      
      },
  
      searchForTradesModal() {
        this.$checkAccessRight("deposit", "Search").then((res) => {
          if (res.data.valid) {
            this.$refs.searchTrades.searchForTradesModal = true;
            this.$refs.searchTrades.getSavedSearches();
          } else {
            this.notAuthToastMessage();
          }
        });
      },
      getSearchedData(data) {
        this.totalRows = data.length;
        if (data.length > 0) {
          this.visibleDepositStatusInput = true;
        }
        this.allDepositData = data;
        this.alldepositNotFilterData = data;
        this.$refs.searchTrades.searchForTradesModal = false;
        this.filterDepositDate = null;
        this.depositRangeDate = null;
        this.filterDepositDateStart = null;
        this.filterDepositDateEnd = null;
        this.filterInstructionDateStart  = null
        this.filterInstructionDateEnd = null
        this.instructionRangeDate = null
        this.filterInstructionDate = null;
      },
      getUpdatedAmendData(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      createNewTrade() {
        this.$checkAccessRight("deposit", "Save").then((res) => {
          if (res.data.valid) {
            this.$refs.refEnterNewTrade.getSavedDefaultEntries();
            
            this.$refs.refEnterNewTrade.enterNewTradeModal = true;
          } else {
            this.notAuthToastMessage();
          }
        });
        
      },
      createddeposit(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      copieddeposit(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      createdJournalEntryArray(journalData) {
        if (journalData.success) {
          this.$refs.refViewJournalEntries.fetchedTradeData = journalData.trade;
          this.$refs.refViewJournalEntries.journalEntries = journalData.entries;
          this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
          this.$refs.refViewJournalEntries.createJournalOperation = true;
        }
      },
      createdJournalEntry(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      reversaledTrade(val) {
        const self = this;
        if (val.info) {
          this.runLastSearch();
          this.$swal({
            title: self.$t("deposit.mail.title"),
            text: self.$t("deposit.mail.message"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: self.$t("deposit.mail.send"),
            cancelButtonText: self.$t("deposit.mail.not_send"),
            customClass: {
              confirmButton: "btn btn-success mr-1",
              cancelButton: "btn btn-danger",
              denyButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              store
                .dispatch("deposit/sendInitiateReversalMail", {
                  auth: self.user,
                  tcUti: val.tcUti,
                  tradeId: val.tradeId,
                  reason: val.reason,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    self.sendedMailSuccess();
                  } else {
                    self.errorMessage(res.data.message);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      },
      reversedEntry(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      undoInitiate(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      verifiedTrade(val) {
        if (val) {
          this.runLastSearch();
        }
      },
      legendClass(item, type) {
        const executedRowColor = "table-executed";
        const verifiedRowColor = "table-verified";
        const journalCreateRowColor = "table-journalCreate";
        const expiredOrderRowColor = "table-expiredOrder";
        const reversedInitiateRowColor = "table-reverseInitiated";
        const reversedRowColor = "table-reversed";
        const canceledRowColor = "table-canceled";
  
        if (!item || type !== "row") {
          return;
        }
  
        if (item.Cancelled == 1) {
          return canceledRowColor;
        }
        // if (item.Reversed == 1) {
        //   return reversedRowColor;
        // }
        // if (item.ReversalInitiated == 1) {
        //   return reversedInitiateRowColor;
        // }
        // if (item.ExpiredOrder == 1) {
        //   return expiredOrderRowColor;
        // }
        // if (item.JournalCreated == 1) {
        //   return journalCreateRowColor;
        // }
        // if (item.Verified == 1) {
        //   return verifiedRowColor;
        // }
        // if (item.EntryType == "Execution") {
        //   return executedRowColor;
        // }
      },
      onRowSelected(row) {
        row._showDetails = !row._showDetails;
      },
      runLastSearch(buttonSearch = false) {
        this.filterDepositDate = null;
        this.depositRangeDate = null;
        this.filterDepositDateStart = null;
        this.filterDepositDateEnd = null;
        this.filterInstructionDateStart  = null
        this.filterInstructionDateEnd = null
        this.instructionRangeDate = null
        this.filterInstructionDate = null;
  
     
          this.$checkAccessRight("deposit", "Search").then((res) => {
            if (res.data.valid) {
              this.loading = true;
              store
                .dispatch("deposit/getLastSearch", {
                  auth: this.user,
                  searchType:'deposit'
                })
                  .then((res) => {

                    if(res.data != 'Not Found'){

                  if(res.data.length>0){

                    this.allDepositData = [];
                    if (res.data.length > 0) {
                      this.visibleDepositStatusInput = true;
                    } else {
                      this.visibleDepositStatusInput = true;
                    }
                    this.totalRows = res.data.length;
                    this.allDepositData = res.data;
                    this.alldepositNotFilterData = res.data;
                    this.loading = false;
  
                        }
                        else{
                          if(buttonSearch) {  
                            this.notFoundSearchData();
                          }
                          this.getLast7DaysTrades();
                        }
                        }
                        else{
                          if(buttonSearch) {  
                            this.notFoundSearchData();
                          }
                        this.getLast7DaysTrades();
                        this.loading = false;
                        }


                   
                  })
                  .catch((error) => {
                    this.loading = false;
                    console.log(error);
                  });
           
            } else {
              this.notAuthToastMessage();
            }
          });
     
      },
  
      
      setTradeData(val) {
        if (
          val.TimeinForce == "GTC" ||
          val.TimeinForce == "FOK" ||
          val.TimeinForce == "FAK"
        ) {
          val.OrderDurationDate = null;
          val.OrderDurationTime = null;
        }
  
        let setTrade = {
            costCenter:val.CostCenter,
            client: val.Client ?? null,
            clientAcc: val.ClientAcc ?? null,
            cpty: val.Cpty ?? null,
            cptyAcc: val.CptyAcc ?? null,
            contractName: val.ContractName ?? null,
            numberOfContracts: val.NumberOfContracts != null 
                ? Number(val.NumberOfContracts) 
                : null,
            contractSize: val.ContractSize != null ? parseFloat(val.ContractSize) : null,
            ccy: val.Ccy ?? null,
            clientPrice: val.ClientPrice != null ? parseFloat(val.ClientPrice) : null,
            executionPrice: val.ExecutionPrice != null ? parseFloat(val.ExecutionPrice) : null,
            clientSpread: val.ClientSpread != null ? parseFloat(val.ClientSpread) : null,
            expiryDate:
              val.ExpiryDate != null
                ? this.dateFormat(val.ExpiryDate)
                : null,
            exchangeTraded: val.ExchangeTraded ?? null,
            ticker: val.Ticker ?? null,
            collPerContract: val.CollPerContract != null ? parseFloat(val.CollPerContract) : null,
            collTotal: val.CollTotal != null ? parseFloat(val.CollTotal) : null,
            clientCommPerContract: val.ClientCommPerContract != null ? parseFloat(val.ClientCommPerContract) : null,
            clientCommTotal: val.ClientCommTotal != null ? parseFloat(val.ClientCommTotal) : null,
            cptyCommPerContract: val.CptyCommPerContract != null ? parseFloat(val.CptyCommPerContract) : null,
            cptyCommTotal: val.CptyCommTotal != null ? parseFloat(val.CptyCommTotal) : null,
            netCommission: val.NetComm != null ? parseFloat(val.NetComm) : null,
            spreadGain: val.SpreadGain != null ? parseFloat(val.SpreadGain) : null,
            totalTcProfit: val.TotalTcProfit != null ? parseFloat(val.TotalTcProfit) : null,
            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null
                ? this.HmstimeFormat(val.OrderDurationTime)
                : null,
            entryType: val.EntryType ?? null,
            cptyBuySell: val.CptyBuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime:
              val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
              settDate:
              val.SettDate != null
                ? this.dateFormat(val.SettDate)
                : null,
                execTime:
              val.ExecTime != null
                ? this.HmstimeFormat(val.ExecTime)
                : null,
            counterpartyTrader: val.CptyTrader ?? null,
            tcUser: val.TcUser ?? null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            actingCap: val.ActingCap ?? null,
            cpRef: val.CpRef ?? null,
            hedgeSpec: val.HedgeSpec ?? null,
            settlementType: val.SettlementType ?? null,
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            note: val.TraderNote ?? null,
            confirmationDate:
              val.ConfirmationDate != null
                ? this.dateFormat(val.ConfirmationDate)
                : null,
            confirmationTime:
              val.ConfirmationTime != null
                ? this.HmstimeFormat(val.ConfirmationTime)
                : null,
            confirmationTrader: val.ConfirmationTrader ?? null,
            confirmationDateClient:
              val.ConfirmationDateClient != null
                ? this.dateFormat(val.ConfirmationDateClient)
                : null,
            confirmationTimeClient:
              val.ConfirmationTimeClient != null
                ? this.HmstimeFormat(val.ConfirmationTimeClient)
                : null,
            cptyUti: val.CptyUti ?? null,
            tcUti: val.TcUti ?? null,
            uniqueLinkId: val.UniqueLinkId ?? null,
            isin: val.Isin ?? null,
            ID: val.id,
          };
  
        return setTrade;
      },
  
      getFilterDepositDate(e) {
        if (this.depositRangeDate != null) {
          this.$checkAccessRight("deposit", "Search").then((res) => {
            if (res.data.valid) {
              let filterDepositDateStart = null;
              let filterDepositDateEnd = null;
              if (
                this.filterDepositDateStart != null &&
                this.filterDepositDateEnd != null
              ) {
                filterDepositDateStart = this.YMDdateFormat(
                  this.filterDepositDateStart
                );
                filterDepositDateEnd = this.YMDdateFormat(this.filterDepositDateEnd);
              }
  
              
                if (filterDepositDateStart != null && filterDepositDateEnd != null) {
                  this.loading = true;
                  store
                    .dispatch("deposit/fetchFilterDepositDate", {
                      auth: this.user,
                      depositStartDate: filterDepositDateStart,
                      depositEndDate: filterDepositDateEnd,
                    })
                    .then((res) => {
                      this.allDepositData = [];
                      if (res.data.filterDatas.length > 0) {
                        this.visibleDepositStatusInput = true;
                        this.totalRows = res.data.filterDatas.length;
                        this.allDepositData = res.data.filterDatas;
                      } else {
                        this.visibleDepositStatusInput = false;
                      }
  
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              // else {
              //   this.notAuthToastMessage();
              // }
            }
          });
        }
      },
      getFilterInstructionDate() {
      if (this.instructionRangeDate != null) {
        this.$checkAccessRight("deposit", "Search").then((res) => {
          if (res.data.valid) {
            
            let filterInstructionDateStart = null;
            let filterInstructionDateEnd = null;

            if (
              this.filterInstructionDateStart != null 
            ) {
              filterInstructionDateStart = this.YMDdateFormat(
                this.filterInstructionDateStart
              );
          
            }

            if (
              
              this.filterInstructionDateEnd != null
            ) {
     
              filterInstructionDateEnd = this.YMDdateFormat(
                this.filterInstructionDateEnd
              );
            }

           
            if (filterInstructionDateStart != null && filterInstructionDateEnd != null) {
              this.loading = true;

              store
                .dispatch("deposit/fetchFilterInstructionDate", {
                  auth: this.user,
                  instructionStartDate: filterInstructionDateStart,
                  instructionEndDate: filterInstructionDateEnd,
                })
                .then((res) => {
                  this.allDepositData = [];
                  if (res.data.filterDatas.length > 0) {
                    this.visibleDepositStatusInput = true;
                    this.totalRows = res.data.filterDatas.length;
                    this.allDepositData = res.data.filterDatas;
                  } else {
                    this.visibleDepositStatusInput = false;
                  }
                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } 
          // else {
          //   this.notAuthToastMessage();
          // }
        });
      }
    },
  
      getChoice() {
        return this.userData.personal_settings.find(
          (e) => e.key == "deposit_tickets"
        ).choice;
      },
      verifySelectedTrade(item) {
        if (item.ID != 0) {
          if (item.Cancelled == 0) {
            this.checkTradeOperations(item).then((res) => {
              if (res == true) {
                store
                  .dispatch("deposit/getVerifySelectedTrade", {
                    auth: this.user,
                    tradeId: item.ID,
                    tcUti: item.TcUti,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                      if (this.getChoice()) {
                        this.$refs.refVerifyTrade.verifySelectedTradeModal = true;
                        this.$refs.refVerifyTrade.fetchedTradeData =
                          res.data.trade;
  
                        // set changes red color inputs
                        if (res.data.changedInputs) {
                          let setChangedInputs = _.map(
                            res.data.changedInputs,
                            (value, key) => ({ key, value })
                          );
                          if (setChangedInputs.length > 0) {
                            this.$refs.refVerifyTrade.changedInputs = setChangedInputs;
                          }
                        }
  
                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != null
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? null,
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime,
                            reason: "-",
                          });
                        }
  
                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != null
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? null,
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime,
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != null
                          ) {
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? null,
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }
  
                        // if (res.data.actions.length > 0) {
                        //   if (
                        //     res.data.actions[0].JournalUser != null &&
                        //     res.data.actions[0].JournalUser != null
                        //   ) {
                        //     actionsArray.push({
                        //       type: "Journal Created",
                        //       user: res.data.actions[0].JournalUser ?? null,
                        //       date: res.data.actions[0].JournalDateTime,
                        //       reason: "-",
                        //     });
                        //   }
                        // }
  
                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != null
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? null,
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime,
                            reason: res.data.trade.CancelReason,
                          });
                        }
  
                        // if (res.data.actions.length > 0) {
                        //   if (
                        //     res.data.actions[0].ReversalInitiatedBy != null &&
                        //     res.data.actions[0].ReversalInitiatedBy != null
                        //   ) {
                        //     actionsArray.push({
                        //       type: "Reversal Initiated",
                        //       user:
                        //         res.data.actions[0].ReversalInitiatedBy ?? null,
                        //       date: res.data.actions[0].ReversalInitiatedDateTime,
                        //       reason: res.data.actions[0].ReversalInitiatedReason,
                        //     });
                        //   }
                        // }
  
                        // if (res.data.actions.length > 0) {
                        //   if (
                        //     res.data.actions[0].ReversedBy != null &&
                        //     res.data.actions[0].ReversedBy != null
                        //   ) {
                        //     actionsArray.push({
                        //       type: "Reversed",
                        //       user: res.data.actions[0].ReversedBy ?? null,
                        //       date: res.data.actions[0].ReversedDateTime,
                        //       reason: "-",
                        //     });
                        //   }
                        // }
  
                        this.$refs.refVerifyTrade.actionsData = actionsArray;
                      } else {
                        let verifyList = [];
                        verifyList.push(item);
                        this.tradeMultipleChangeStatusOpr(
                          1,
                          verifyList,
                          "Verify"
                        );
                      }
                    } else {
                      this.notFoundMessage();
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.tradeIDDoesntZeroMessage();
        }
      },
      amendUpdate(item) {
        this.$checkAccessRight("deposit", "Amend").then((res) => {
          if (res.data.valid) {
            if (item.ID != 0) {
              if (item.Cancelled == 0) {
                store
                      .dispatch("deposit/getAmendSelectedTrade", {
                        auth: this.user,
                        tradeId: item.ID,
                        tcUti: item.TcUti,
                      })
                      .then((res) => {
                        if (res.data.info == "Pass") {
                          this.getAllAccounts();
                          this.$refs.refAmendTrade.fetchedTradeData =
                            res.data.trade;
                          this.$refs.refAmendTrade.amendTradeModal = true;
                       

                          //set timeline datas
                          let actionsArray = [];
                          if (
                            res.data.trade.SavedBy != null &&
                            res.data.trade.SavedBy != null
                          ) {
                            actionsArray.push({
                              type: "Saved",
                              user: res.data.trade.SavedBy ?? null,
                              date:
                                res.data.trade.SaveDate.split(" ")[0] +
                                " " +
                                res.data.trade.SaveTime,
                              reason: "-",
                            });
                          }
  
                          if (
                            res.data.trade.AmendedBy != null &&
                            res.data.trade.AmendedBy != null
                          ) {
                            actionsArray.push({
                              type: "Amended",
                              user: res.data.trade.AmendedBy ?? null,
                              date:
                                res.data.trade.AmendmentDate.split(" ")[0] +
                                " " +
                                res.data.trade.AmendmentTime,
                              reason: res.data.trade.AmendmentReason,
                            });
                          }
                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].VerificationUser != null &&
                              res.data.actions[0].VerificationUser != null
                            ) {
                              this.$refs.refAmendTrade.checkVerify = true;
                              actionsArray.push({
                                type: "Verified",
                                user:
                                  res.data.actions[0].VerificationUser ?? null,
                                date: res.data.actions[0].VerificationDateTime,
                                reason: "-",
                              });
                            }
                          }
                          if (
                            res.data.trade.CancelledBy != null &&
                            res.data.trade.CancelledBy != null
                          ) {
                            actionsArray.push({
                              type: "Canceled",
                              user: res.data.trade.CancelledBy ?? null,
                              date:
                                res.data.trade.CancelDate.split(" ")[0] +
                                " " +
                                res.data.trade.CancelTime,
                              reason: res.data.trade.CancelReason,
                            });
                          }
  
                          this.$refs.refAmendTrade.actionsData = actionsArray;
                        } else {
                          this.notFoundMessage();
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
              } else {
                this.canceledToastMessage();
              }
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      },
      viewSelectedTrade(item) {
        this.$checkAccessRight("deposit", "View").then((res) => {
          if (res.data.valid) {
            if (item.ID != 0) {
              store
                .dispatch("deposit/getViewSelectedTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                    this.$refs.ViewSelectedTrade.fetchedTradeData =
                      res.data.trade;
  
  
                    // set changes red color inputs
                    if (res.data.changedInputs) {
                        let setChangedInputs = _.map(
                          res.data.changedInputs,
                          (value, key) => ({ key, value })
                        );
                        if (setChangedInputs.length > 0) {
                          this.$refs.ViewSelectedTrade.changedInputs = setChangedInputs;
                        }
                      }
  
                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != null
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? null,
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime,
                        reason: "-",
                      });
                    }
  
                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != null
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? null,
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime,
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != null
                      ) {
                        this.$refs.ViewSelectedTrade.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? null,
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }
  
                    // if (res.data.actions.length > 0) {
                    //   if (
                    //     res.data.actions[0].JournalUser != null &&
                    //     res.data.actions[0].JournalUser != null
                    //   ) {
                    //     actionsArray.push({
                    //       type: "Journal Created",
                    //       user: res.data.actions[0].JournalUser ?? null,
                    //       date: res.data.actions[0].JournalDateTime,
                    //       reason: "-",
                    //     });
                    //   }
                    // }
  
                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != null
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? null,
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime,
                        reason: res.data.trade.CancelReason,
                      });
                    }
  
                    // if (res.data.actions.length > 0) {
                    //   if (
                    //     res.data.actions[0].ReversalInitiatedBy != null &&
                    //     res.data.actions[0].ReversalInitiatedBy != null
                    //   ) {
                    //     actionsArray.push({
                    //       type: "Reversal Initiated",
                    //       user: res.data.actions[0].ReversalInitiatedBy ?? null,
                    //       date: res.data.actions[0].ReversalInitiatedDateTime,
                    //       reason: res.data.actions[0].ReversalInitiatedReason,
                    //     });
                    //   }
                    // }
  
                    // if (res.data.actions.length > 0) {
                    //   if (
                    //     res.data.actions[0].ReversedBy != null &&
                    //     res.data.actions[0].ReversedBy != null
                    //   ) {
                    //     actionsArray.push({
                    //       type: "Reversed",
                    //       user: res.data.actions[0].ReversedBy ?? null,
                    //       date: res.data.actions[0].ReversedDateTime,
                    //       reason: "-",
                    //     });
                    //   }
                    // }
  
                    this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      },
      copySelectedTrade(item) {
        this.$checkAccessRight("deposit", "Save").then((res) => {
          if (res.data.valid) {
            store
              .dispatch("deposit/getCopySelectedTrade", {
                auth: this.user,
                tradeId: item.ID,
                tcUti: item.TcUti,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  if (this.getChoice()) {
                    this.getAllAccounts();
                    this.$refs.refCopyTrade.fetchedTradeData = res.data.trade;
                    this.$refs.refCopyTrade.copyTradeModal = true;
                    
  
                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != null
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? null,
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime,
                        reason: "-",
                      });
                    }
  
                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != null
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? null,
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime,
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != null
                      ) {
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? null,
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }
  
                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != null
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? null,
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime,
                        reason: res.data.trade.CancelReason,
                      });
                    }
  
                    this.$refs.refCopyTrade.actionsData = actionsArray;
                  } else {
                    let verifyList = [];
                    verifyList.push(item);
                    this.tradeMultipleSelectCopyOpr(1, verifyList);
                  }
                } else {
                  this.notFoundMessage();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.notAuthToastMessage();
          }
        });
      },
      getAmendDetails(item) {
        this.$checkAccessRight("deposit", "View").then((res) => {
          if (res.data.valid) {
            if (item.Cancelled == 0) {
              if (item.ID != 0) {
                this.$router.push({
                  path: `/deposit/amend-details/${item.ID}`,
                });
              } else {
                this.tradeIDDoesntZeroMessage();
              }
            } else {
              this.canceledToastMessage();
            }
          }
        });
      },
      checkTradeOperations(trade) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("deposit/checkTradeVerifyOperation", {
              auth: this.user,
              trade: trade,
            })
            .then((res) => {
              if (res.data.info == "not_verified") {
                store
                  .dispatch("deposit/checkTradeUserOperation", {
                    auth: this.user,
                    trade: trade,
                  })
                  .then((res) => {
                    if (res.data.info == "user_doesnt_same") {
                      if (trade.EntryType == "Order") {
                        this.$swal({
                          title: this.$t("deposit.swal.order_trade"),
                          text: this.$t(
                            "deposit.swal.entry_saved_order_want_amend_trade"
                          ),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: this.$t(
                            "deposit.swal.confirm_button"
                          ),
                          cancelButtonText: this.$t(
                            "deposit.swal.cancel_button"
                          ),
                          customClass: {
                            confirmButton: "btn btn-success mr-1",
                            cancelButton: "btn btn-danger",
                            denyButton: "btn btn-primary",
                          },
                          showClass: {
                            popup: "animate__animated animate__fadeInDown",
                          },
                          hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.amendUpdate(trade);
                          }
                        });
                      } else {
                        resolve(true);
                      }
                    } else if (
                      res.data.info == "same_user_amend" ||
                      res.data.info == "same_user_save"
                    ) {
                      this.checkTradeUserOperationMessage(res.data.message, 8000);
                    } else {
                      this.checkTradeUserOperationMessage(res.data.message, 8000);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                if (res.data.info == "verified") {
                  this.checkTradeVerifyOperationLangMessage(
                    res.data.user,
                    res.data.date
                  );
                } else {
                  this.checkTradeVerifyOperationMessage(res.data.message);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      },
      checkTradeVerifyOperationMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      checkTradeVerifyOperationLangMessage(user, date) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("backend_language_message.verify_trade1") +
              " " +
              user +
              " " +
              this.$t("backend_language_message.verify_trade_on") +
              " " +
              date,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      checkTradeUnVerifyOperationLangMessage() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("backend_language_message.un_verify_message"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      checkTradeUserOperationMessage(message, timeout) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              text: message,
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          },
          {
            timeout,
          }
        );
      },
      checkEntryReversed(messageType, tcUti) {
        if (tcUti != null && tcUti != null) {
          return new Promise((resolve, reject) => {
            store
              .dispatch("deposit/checkTCUtiReserved", {
                auth: this.user,
                type: messageType,
                tcUti: tcUti,
              })
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          });
        } else {
          this.errorMessage(this.$t("deposit.messages.tc_uti_not_found"));
        }
      },
      checkReversalInitiated(messageType, tcUti) {
        if (tcUti != null && tcUti != null) {
          return new Promise((resolve, reject) => {
            store
              .dispatch("deposit/checkReversalInitiated", {
                auth: this.user,
                type: messageType,
                tcUti: tcUti,
              })
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          });
        } else {
          this.errorMessage(this.$t("deposit.messages.tc_uti_not_found"));
        }
      },
      checkEntryReversedMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      checkReversalInitiatedMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      databaseErrorMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.$t("deposit.toast_messages.database_connection_error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      },
      errorMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      },
      notFoundMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.$t("deposit.toast_messages.data_not_found"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      canceledToastMessage() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("deposit.messages.trade_cancelled_message"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      cancelOpearionNotDoneMessage() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("deposit.messages.cancelled_operation_not_done"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      notFoundMatchContracts() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("deposit.messages.not_found_match_contracts"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      autoMatchContracts() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully",
            text: this.$t("deposit.messages.auto_match_contracts"),
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      },
      multiSelectAllProcesesSuccessMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully",
            text: this.$t("multiCheckBoxTitles.multiselect_processes_success"),
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
      },
      multiSelectAllProcesesWarningMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("multiCheckBoxTitles.multiselect_processes_warning"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      multiSelectCopyWarningMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("multiCheckBoxTitles.multiselect_copy_warning"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      multiSelectAllTradesCopySuccessMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully",
            text: this.$t("multiCheckBoxTitles.multiselect_copy_success"),
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
      },
      sendedMailSuccess() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully",
            text: this.$t("transfers.mail.mail_send_success"),
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      },
      tradeCancelledErrorMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      },
      wrongToastMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: val,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      warningToastMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: val,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      notFoundSearchData() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Not Found Search Data",
            text: this.$t("deposit.messages.not_found_search_data"),
            icon: "UserXIcon",
            variant: "warning",
          },
        });
      },
      notAuthToastMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Not Authorized",
            text: this.$t("general_messages.not_authorized_message"),
            icon: "UserXIcon",
            variant: "warning",
          },
        });
      },
      tradeCancelledMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully",
            text: this.$t("deposit.toast_messages.trade_cancelled_success"),
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
      },
      successOrderColumnsMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Successfully",
            text: this.$t("deposit.toast_messages.success_order_columns"),
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
      },
  
      tradeIDDoesntZeroMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("deposit.messages.trade_id_cannot_zero"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      validReasonTextMessage() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t("deposit.reason.enter_valid_reason"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      dateFormat(val) {
        if (val != undefined) {
          return moment(String(val)).format("YYYY-MM-DD");
        }
      },
      dateFormatWithTime(val) {
        if (val != undefined) {
          return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
        }
      },
      DMYdateFormat(val) {
        if (val != undefined) {
          return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
        }
      },
      timeFormat(val) {
        if (val != undefined) {
          return moment(String(val)).format("HH:mm:ss");
        }
      },
      HmstimeFormat(val) {
        if (val != undefined) {
          return moment(String(val), "HH:mm:ss")._i;
        }
      },
      formatPrice(value, val) {
        if (value != null) {
          let val = (value / 1).toFixed(2).replace(",", ".");
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      YMDdateFormat(val) {
        if (val != undefined) {
          return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
        }
      },
      clearDepositDate() {
        this.filterDepositDate = null;
        this.depositRangeDate = null;
        this.filterDepositDateStart = null;
        this.filterDepositDateEnd = null;
        this.runLastSearch();
      },
      clearInstructionDate() {
      this.filterInstructionDate = null;
      this.filterInstructionDateStart  = null
      this.filterInstructionDateEnd = null
      this.instructionRangeDate = null
      this.runLastSearch();
    },
      handleScroll(e) {
        // this.windowTop = window.top.scrollY
        // var sticky = this.$refs.refdepositTable.$el.offsetTop;
        // if(this.windowTop + -30 > sticky) {
        //   this.$refs.refdepositTable.$el.children[0].children[0].classList.add("fixed_header_sticky")
        //   //console.log(this.$refs.refdepositTable.$el.children[0].children[0])
        // } else {
        //   this.$refs.refdepositTable.$el.children[0].children[0].classList.remove("fixed_header_sticky")
        // }
      },
    },
    watch: {
      perPage: {
        handler: function(val, before) {
          localStorage.setItem("depositTradesShow", val);
        },
      },
      // tableColumns:{
      //   handler: function(val, before) {
          
      //   },
      // },
      depositRangeDate: {
        handler: function(val, before) {
          if (val) {
            if (val.includes("to")) {
              this.filterDepositDateStart = this.depositRangeDate.split(" to ")[0];
              this.filterDepositDateEnd = this.depositRangeDate.split(" to ")[1];
  
              if (
                this.filterDepositDateStart != "null" &&
                this.filterDepositDateEnd != "null"
              ) {
              } else {
                this.filterDepositDateStart = null;
                this.filterDepositDateEnd = null;
              }
            }
          }
        },
      },
      instructionRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterInstructionDateStart = this.instructionRangeDate.split(" to ")[0];
            this.filterInstructionDateEnd = this.instructionRangeDate.split(" to ")[1];

            if (
              this.filterInstructionDateStart != "null" &&
              this.filterInstructionDateEnd != "null"
            ) {
            } else {
              this.filterInstructionDateStart = null;
              this.filterInstructionDateEnd = null;
            }
          }
          else{
            this.filterInstructionDateStart = this.instructionRangeDate;
          }
        }
      },
    },
      treeStatus: {
        handler: function(val, before) {
          let allFilteredData = [];
  
          if (val.length > 0) {
            val.forEach((s) => {
              if (s.value == "Execution") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  EntryType: "Execution",
                });
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              } else if (s.value == "Expired Order") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  EntryType: "Order",
                });
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              } else if (s.value == "Verified") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  Verified: 1,
                  JournalCreated: 0,
                  ReversalInitiated: 0,
                  Reversed: 0,
                  Cancelled: 0,
                });
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              } else if (s.value == "Journal Created") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  Verified: 1,
                  JournalCreated: 1,
                  ReversalInitiated: 0,
                  Reversed: 0,
                  Cancelled: 0,
                });
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              } else if (s.value == "Reverse Initiated") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  Verified: 1,
                  JournalCreated: 1,
                  ReversalInitiated: 1,
                  Reversed: 0,
                  Cancelled: 0,
                });
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              } else if (s.value == "Reversed") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  Verified: 1,
                  JournalCreated: 1,
                  ReversalInitiated: 1,
                  Reversed: 1,
                  Cancelled: 0,
                });
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              } else if (s.value == "Cancelled") {
                let allRawData = JSON.parse(
                  JSON.stringify(this.alldepositNotFilterData)
                );
                let filteredData = _.filter(allRawData, {
                  Cancelled: 1,
                });
  
                if (filteredData.length > 0) {
                  filteredData.forEach((d) => {
                    allFilteredData.push(d);
                  });
                }
              }
            });
  
            let uniqueData = _.uniqBy(allFilteredData, function(e) {
              return e.ID;
            });
  
            this.allDepositData = uniqueData;
            this.totalRows = uniqueData.length;
          } else {
            this.allDepositData = this.alldepositNotFilterData;
            this.totalRows = this.alldepositNotFilterData.length;
          }
        },
      },
      selectedMultipleTrades: {
        handler: function(val, old) {
          if (val.length > 0) {
            this.multiSelectListStatusData = [];
            // if (
            //   _.filter(val, {
            //     EntryType: "Execution",
            //     Verified: 0,
            //     Cancelled: 0,
            //   }).length > 0
            // ) {
            //   this.multiSelectListStatusData.push({
            //     key: "Verify",
            //     title: this.$t("multiCheckBoxTitles.verify"),
            //     icon: "CheckSquareIcon",
            //     color: "green",
            //   });
            // }
            if (
              _.filter(val, {
                Cancelled: 0,
              }).length > 0
            ) {
              this.multiSelectListStatusData.push({
                key: "Cancel",
                title: this.$t("multiCheckBoxTitles.cancel"),
                icon: "XSquareIcon",
                color: "#ea5455",
              });
            }
          } else {
            this.multiSelectListStatusData = [];
          }
        },
      },
    },
  };
  </script>
  
  <style>
  
  .table-executed {
    background-color: #e9fafa !important;
  }
  
  .table-verified {
    background-color: #00ff7f !important;
  }
  
  .table-journalCreate {
    background-color: #e1d7f5 !important;
  }
  
  .table-expiredOrder {
    background-color: silver !important;
  }
  
  .table-reverseInitiated {
    background-color: #ffff99 !important;
  }
  
  .table-reversed {
    background-color: #f8ac91 !important;
  }
  
  .table-canceled {
    background-color: #ffb8b8 !important;
  }
  
  .TcUti {
    width: 100%;
  }
  
  .column_search_input {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    min-width: 150px !important;
  }
  
  .ID_column_search_input {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
  }
  
  .sticky-column {
    position: sticky;
    background-color: #fff;
    z-index: 1;
  }
  .sticky-column:nth-child(1) {
    left: 0;
  }
  .sticky-column:nth-child(2) {
    left: 50px;
  }
  .other-col {
    z-index: 0;
  }
  
  /* .b-table-sticky-column:last-child {
    right: 0;
  } */
  
  
  .ID_background {
    background-color: gainsboro !important;
  }
  
  
  .sticky-header {
    position: sticky;
    top: 78px;
    z-index: 999;
    /* Add other styling as needed */
  }
  
  
  .b-table-sticky-header{
    max-height : unset !important;
    height: inherit !important;
  
  }
  
  
  .hide-rows thead tr[role="row"] { display: none; }
  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(77, 73, 73); 
  }
  
  
  
  </style>
  
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "~vue-context/src/sass/vue-context";
  </style>
  
  
  <!-- compact design scss code -->
  
  <style lang="scss" scoped>
  
  @import "../../assets/scss/compact-design.scss";
  
  
  .card::v-deep .card-body {
      padding: 0.8rem 0.7rem !important;
    
      .card-title {
          font-weight: 500 !important;
          font-size: 1.285rem !important;
          margin-bottom: 0.6rem !important;
          text-decoration: none !important;
      }
    }
  
  // trade controls buttons
    .top-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  </style>