<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="viewSelectedTradeModal"
      :title="$t('swap_future.titles.view_selected_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('swap_future.modals.view_trade_modal_ok')"
      :cancel-title="$t('swap_future.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5 style="color: white;">
          {{ $t("swap_future.titles.view_selected_trade") }}
        </h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
          <b style="color: white;">
            {{ $t("swap_future.modals.trade_not_verified") }}
          </b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
          <b style="color: white;">
            {{ $t("swap_future.modals.verified_trade") }}
          </b>
        </p>
      </template>
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                  <b-card
                    :title="
                      $t('swap_future.titles.client_counterparty_details')
                    "
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.client')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client')"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.client"
                                ref="client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t('swap_future.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :filterable="true"
                                :disabled="true"
                                class="compact_vue-select"
                                style="width: 100%"
                              />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client_account')"
                          >
                            <b-form-input
                              id="clientAccount"
                              ref="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.client_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.counterparty')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.counterparty')"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.counterparty"
                                ref="cpty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :filterable="true"
                                class="compact_vue-select"
                                style="width: 100%;"
                                :disabled="true"
                              />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.cpty_account')"
                          >
                            <b-form-input
                              id="cptyAccount"
                              ref="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Order Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.order_details')">
                    <b-row>
                      <!-- order date-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_date')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.order_date')
                              "
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control "
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              ref="orderDate"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_time')"
                          >
                            <cleave
                              id="orderTime"
                              ref="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control "
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_duration_date')
                          "
                          class="mobile-label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_duration_date')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.order_duration_date')
                              "
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control"
                              ref="orderDurationDate"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_duration_time')
                          "
                          class="mobile-label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_duration_time')
                            "
                          >
                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control"
                              ref="orderDurationTime"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.order_duration_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- entry type -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.entry_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.entry_type')"
                          >
                            <v-select
                              v-model="tradeForm.entryType"
                              ref="entryType"
                              :options="optEntryTypes"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_entry_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- buy/sell -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.buy_sell')"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              ref="buySell"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order type-->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_type')"
                          >
                            <v-select
                              v-model="tradeForm.orderType"
                              :options="optOrderTypes"
                              ref="orderType"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_order_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- time in force-->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.time_in_force')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.time_in_force')"
                          >
                            <v-select
                              v-model="tradeForm.timeInForce"
                              :options="optTimeInForces"
                              :clearable="false"
                              ref="timeinForce"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_time_in_force'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cost center -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.cost_center')"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              ref="costCenter"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Trade Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.trade_details')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.trade_date')"
                          >
                            <b-input-group
                              :class="
                                tradeForm.tradeDateName == 'Sunday' ||
                                tradeForm.tradeDateName == 'Saturday'
                                  ? 'tradeDateName'
                                  : ''
                              "
                              :append="tradeForm.tradeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.trade_date')
                                "
                                v-model="tradeForm.tradeDate"
                                ref="tradeDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Settlement Date -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.settlement_date')"
                          >
                            <b-input-group
                              :class="
                                tradeForm.settlementDateName == 'Sunday' ||
                                tradeForm.settlementDateName == 'Saturday'
                                  ? 'settlementDateName'
                                  : ''
                              "
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.settlement_date')
                                "
                                v-model="tradeForm.settlementDate"
                                ref="settDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.execution_time')"
                          >
                            <cleave
                              id="executionTime"
                              ref="execTime"
                              v-model="tradeForm.executionTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party trader -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              ref="cptyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              class="compact_vue-select"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfc user -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.sfc_user')"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              ref="tcUser"
                              :options="optScfUsers"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_taken_via')"
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              ref="orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken through -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              ref="orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.acting_capacity')"
                          >
                            <v-select
                              v-model="tradeForm.actingCapaticy"
                              ref="actingCap"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Hedge/Spec -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.hedgespec')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.hedgespec')"
                          >
                            <v-select
                              v-model="tradeForm.hedgeSpec"
                              ref="hedgeSpec"
                              :options="optHedgeSpecs"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.select_hedgespec')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- settlement type-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.settlement_type')"
                          >
                            <v-select
                              v-model="tradeForm.settlementType"
                              ref="settlementType"
                              :options="optSettlementTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Spot Ref -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                          >
                            <cleave
                              id="spot_ref"
                              ref="spotRef"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('swap_future.trade_form.spot_ref')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('swap_future.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                              ref="note"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /*******************************Contract Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.contract_details')">
                    <b-row>
                      <!-- Contract name -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.contract_name')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.validation_contract_name'
                              )
                            "
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                ref="contractName"
                                v-model="tradeForm.contractName"
                                :options="optContractNames"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_contract_name'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="false"
                                :state="errors.length > 0 ? false : null"
                                style="width:100%"
                                :disabled="true"
                              />
                              <!-- <i class="fa-solid fa-circle-info fa-xs input-dropdown animate__animated animate__fadeIn"
                          style="padding-left:0.2rem"
                          v-b-tooltip.hover.top="'After entering the contract name you want to add, press enter.'"
                          ></i> -->
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Number of Contracts -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.of_contracts')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.of_contracts')"
                          >
                            <b-form-input
                              id="numberOfContracts"
                              v-model.number="tradeForm.numberOfContracts"
                              name="numberOfContracts"
                              :placeholder="
                                $t('swap_future.trade_form.of_contracts')
                              "
                              type="number"
                              ref="numberOfContracts"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- client price -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.client_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client_price')"
                          >
                            <cleave
                              ref="clientPrice"
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('swap_future.trade_form.client_price')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- contract currency type ccy -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.contract_currency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.contract_currency')
                            "
                          >
                            <v-select
                              ref="contractCcy"
                              v-model="tradeForm.contractCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.contract_currency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                       <!-- Contract Size -->
                       <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.contract_size')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.contract_size')"
                          >
                            <cleave
                              ref="contractSize"
                              id="contractSize"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.contractSize"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('swap_future.trade_form.contract_size')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>



                      <!-- execution price -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('swap_future.trade_form.execution_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.execution_price')"
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.executionPriceCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="executionPrice"
                                id="executionPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.executionPrice"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.execution_price')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange Fee -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.exchange_fee')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.exchange_fee')"
                          >
                            <b-input-group
                              :append="tradeForm.exchangeFeeCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="exchangeFee"
                                id="exchangeFee"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.exchangeFee"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.exchange_fee')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- expiry Date -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.expiry_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.expiry_date')"
                          >
                            <b-input-group
                              :class="
                                tradeForm.expiryDateName == 'Sunday' ||
                                tradeForm.expiryDateName == 'Saturday'
                                  ? 'expiryDateName'
                                  : ''
                              "
                              :append="tradeForm.expiryDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.expiry_date')
                                "
                                v-model="tradeForm.expiryDate"
                                onClose="testClose"
                                ref="expiryDate"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange traded & exchange type -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.exchange_traded')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.exchange_traded')"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.exchangeTraded"
                                ref="exchangeTraded"
                                :options="optExchangeTrades"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_exchange_traded'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="false"
                                :disabled="true"
                                style="width:100%"
                              />
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral Per Contract -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.coll_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.coll_per_contract')
                            "
                          >
                            <b-input-group
                              :append="tradeForm.collPerContractCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="collPerContract"
                                ref="collPerContract"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collPerContract"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.coll_per_contract')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- total collateral -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_colleteral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.total_colleteral')
                            "
                          >
                            <b-input-group
                              :append="tradeForm.collTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="collTotal"
                                ref="collTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_colleteral')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Ticker -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.ticker')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.ticker')"
                          >
                            <b-form-input
                              id="ticker"
                              ref="ticker"
                              v-model="tradeForm.ticker"
                              name="ticker"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.ticker')"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Comm Per Contract -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.client_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.client_per_contract')
                            "
                          >
                            <b-input-group
                              :append="
                                tradeForm.clientCommPerContractCcy != null
                                  ? tradeForm.clientCommPerContractCcy + '/Bps'
                                  : 'Bps'
                              "
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="clientCommPerContract"
                                ref="clientCommPerContract"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommPerContract"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.client_per_contract'
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Comm Total -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.total_client_comm')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.total_client_comm')
                            "
                          >
                            <b-input-group
                              :append="tradeForm.clientCommTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="clientCommTotal"
                                ref="clientCommTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_client_comm')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Comm Per Contract -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.cpty_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.cpty_per_contract')
                            "
                          >
                            <b-input-group
                              :append="
                                tradeForm.cptyCommPerContractCcy != null
                                  ? tradeForm.cptyCommPerContractCcy + '/Bps'
                                  : 'Bps'
                              "
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="cptyCommPerContract"
                                ref="cptyCommPerContract"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommPerContract"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.cpty_per_contract')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Comm Total -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_cpty_comm')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.total_cpty_comm')"
                          >
                            <b-input-group
                              :append="tradeForm.cptyCommTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="cptyCommTotal"
                                ref="cptyCommTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_cpty_comm')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Net Comission -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.net_comm')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.net_comm')"
                          >
                            <b-input-group
                              :append="tradeForm.netCommissionCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="netCommission"
                                ref="netCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.netCommission"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.net_comm')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Spread Gain -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.spead_gain')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.spead_gain')"
                          >
                            <b-input-group
                              :append="tradeForm.spreadGainCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="spreadGain"
                                ref="spreadGain"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.spreadGain"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.spead_gain')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Total Tc Profit -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_tc_profit')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.total_tc_profit')"
                          >
                            <b-input-group
                              :append="tradeForm.totalTcProfitCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="totalTcProfit"
                                ref="totalTcProfit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.totalTcProfit"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('swap_future.trade_form.total_tc_profit')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notice date -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.notice_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.notice_date')"
                          >
                          <b-input-group
                              :class="
                                tradeForm.noticeDateName == 'Sunday' ||
                                tradeForm.noticeDateName == 'Saturday'
                                  ? 'noticeDateName'
                                  : ''
                              "
                              :append="tradeForm.noticeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.notice_date')
                                "
                                v-model="tradeForm.noticeDate"
                                onClose="testClose"
                                ref="noticeDate"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- /******************************* Settlement Details***************************************************************** */ -->

                  <b-card :title="$t('swap_future.titles.settlement_details')">
                    <b-row>
                      <!-- Cpty Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.ConfirmationDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationDate')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationDate')
                              "
                              v-model="tradeForm.confirmationDate"
                              onClose="testClose"
                              ref="confirmationDate"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.ConfirmationTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationTime')
                            "
                          >
                            <cleave
                              id="confirmationTime"
                              ref="confirmationTime"
                              v-model="tradeForm.confirmationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationTime')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Trader -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationTrader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationTrader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.confirmationTrader"
                              ref="confirmationTrader"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationTrader')
                              "
                              :reduce="(val) => val"
                              :filterable="true"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Client Confo Date -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationDateClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.ConfirmationDateClient'
                              )
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.ConfirmationDateClient'
                                )
                              "
                              v-model="tradeForm.confirmationDateClient"
                              ref="confirmationDateClient"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationTimeClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.ConfirmationTimeClient'
                              )
                            "
                          >
                            <cleave
                              id="confirmationTimeClient"
                              ref="confirmationTimeClient"
                              v-model="tradeForm.confirmationTimeClient"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.ConfirmationTimeClient'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty MTM Ref  -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.CpRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.CpRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CpRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.CpRef')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty UTI  -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.CptyUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.CptyUti')"
                          >
                            <b-form-input
                              id="cptyUti"
                              ref="cptyUti"
                              v-model="tradeForm.cptyUti"
                              name="cptyUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.CptyUti')
                              "
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Tc Uti  -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.TcUti')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.UniqueLinkId')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- ISIN -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.isin')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="ISIN"
                          >
                            <b-form-input
                              id="isin"
                              ref="isin"
                              v-model="tradeForm.isin"
                              :name="$t('swap_future.trade_form.isin')"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.isin')"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- trade ref -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="$t('swap_future.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.trade_ref')"
                          >
                            <b-input-group
                              :prepend="$t('swap_future.trade_form.trade_ref')"
                            >
                              <b-form-input
                                style="background: aliceblue !important;"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('swap_future.trade_form.trade_ref')
                                "
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b>
                {{ $t("swap_future.overlay_messages.verifying_message") }}</b
              >
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("swap_future.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("swap_future.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>

            <!-- changed input message -->
            <p class="d-inline ml-1 mb-0" v-if="changedInputs.length > 0">
              <b-icon
                class="ml-1"
                title=""
                icon="record-fill"
                style="color:orange!important"
                data-toggle="tooltip"
                data-placement="top"
                font-scale="0.5"
              ></b-icon
              ><b style="margin-left:0.3rem;color:#6e6b7b;font-size: 0.7rem;">{{
                $t("swap_future.modals.inputs_written_red_inputs")
              }}</b>
            </p>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center mobile-footer"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("swap_future.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="tradeOkOperation"
            >
              {{ $t("swap_future.modals.view_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** view for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import depositStoreModule from "./depositStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
      const deposit_APP_STORE_MODULE_NAME = "deposit";
      // Register module
      if (!store.hasModule(deposit_APP_STORE_MODULE_NAME))
        store.registerModule(
          deposit_APP_STORE_MODULE_NAME,
          depositStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(deposit_APP_STORE_MODULE_NAME))
          store.unregisterModule(deposit_APP_STORE_MODULE_NAME);
      });
    },

  mounted() {},
  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      viewSelectedTradeModal: false,
      checkVerify: false,
      tradeModalLoading: false,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optHedgeSpecs: ["HEDGE", "SPECULATION"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optContractNames: [],
      optPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      actionsData: null,
      changedInputs: [],

      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //option details
        contractName: null,
        numberOfContracts: null,
        contractSize: null,
        clientPrice: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalCcy: null,
        clientCommPerContract: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName: null,
        orderTime: moment().format("HH:mm:ss"),
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        noticeDate:null,
        noticeDateName:null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: "PRINCIPAL",
        hedgeSpec: "HEDGE",
        settlementType: "CASH",
        spotRef: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
        tradeRef: null,
      },
      changedValuesForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //option details
        contractName: null,
        numberOfContracts: null,
        contractSize: null,
        clientPrice: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalCcy: null,
        clientCommPerContract: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName: null,
        orderTime: moment().format("HH:mm:ss"),
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        noticeDate:null,
        noticeDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: "PRINCIPAL",
        hedgeSpec: "HEDGE",
        settlementType: "CASH",
        spotRef: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
        tradeRef: null,
      },

      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchdeposit"]),
    getArangedepositTCUsers() {
      store
        .dispatch("deposit/fetchArangedepositTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.optPriceTypes = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("swap_future.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllExchanges() {
      store
        .dispatch("deposit/getAllExchanges")
        .then((res) => {
          this.optExchangeTrades = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFutureContractSpecs() {
      store
        .dispatch("deposit/getFutureContractSpecs")
        .then((res) => {
          this.optContractNames = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangedepositOrderTakenGivenThrough() {
      store
        .dispatch(
          "deposit/fetchArrangedepositOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(
              this.$t("swap_future.messages.not_found_given_order_data")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangedepositCurrencies() {
      store
        .dispatch("deposit/fetchArrangedepositCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.viewSelectedTradeModal = false;
      this.actionsData = null;
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    copyCurrencies() {
      let ccy = JSON.parse(JSON.stringify(this.tradeForm.contractCcy));
      this.tradeForm.executionPriceCcy = ccy;
      this.tradeForm.exchangeFeeCcy = ccy;
      this.tradeForm.collTotalCcy = ccy;
      this.tradeForm.clientCommPerContractCcy = ccy;
      this.tradeForm.clientCommTotalCcy = ccy;
      this.tradeForm.cptyCommPerContractCcy = ccy;
      this.tradeForm.cptyCommTotalCcy = ccy;
      this.tradeForm.netCommissionCcy = ccy;
      this.tradeForm.spreadGainCcy = ccy;
      this.tradeForm.totalTcProfitCcy = ccy;
    },
    resetTradeModal() {
      this.viewSelectedTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.checkVerify = false;
      this.changedInputs = [];
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    arrangeInputFormats() {
      if (this.tradeForm.collPerContract != null) {
        this.tradeForm.collPerContract = Number.parseFloat(
          this.tradeForm.collPerContract
        ).toFixed(2);
      }
      if (this.tradeForm.collTotal != null) {
        this.tradeForm.collTotal = Number.parseFloat(
          this.tradeForm.collTotal
        ).toFixed(2);
      }
      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = Number.parseFloat(
          this.tradeForm.clientCommPerContract
        ).toFixed(2);
      }
      if (this.tradeForm.clientCommTotal != null) {
        this.tradeForm.clientCommTotal = Number.parseFloat(
          this.tradeForm.clientCommTotal
        ).toFixed(2);
      }
      if (this.tradeForm.clientPrice != null) {
        this.tradeForm.clientPrice = Number.parseFloat(
          this.tradeForm.clientPrice
        ).toFixed(2);
      }
      if (this.tradeForm.exchangeFee != null) {
        this.tradeForm.exchangeFee = Number.parseFloat(
          this.tradeForm.exchangeFee
        ).toFixed(2);
      }
      if (this.tradeForm.executionPrice != null) {
        this.tradeForm.executionPrice = Number.parseFloat(
          this.tradeForm.executionPrice
        ).toFixed(2);
      }
      if (this.tradeForm.cptyCommPerContract != null) {
        this.tradeForm.cptyCommPerContract = Number.parseFloat(
          this.tradeForm.cptyCommPerContract
        ).toFixed(2);
      }
      if (this.tradeForm.cptyCommTotal != null) {
        this.tradeForm.cptyCommTotal = Number.parseFloat(
          this.tradeForm.cptyCommTotal
        ).toFixed(2);
      }
      if (this.tradeForm.netCommission != null) {
        this.tradeForm.netCommission = Number.parseFloat(
          this.tradeForm.netCommission
        ).toFixed(2);
      }
      if (this.tradeForm.spreadGain != null) {
        this.tradeForm.spreadGain = Number.parseFloat(
          this.tradeForm.spreadGain
        ).toFixed(2);
      }
      if (this.tradeForm.totalTcProfit != null) {
        this.tradeForm.totalTcProfit = Number.parseFloat(
          this.tradeForm.totalTcProfit
        ).toFixed(2);
      }
      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(2);
      }
      if (this.tradeForm.contractSize != null) {
        this.tradeForm.contractSize = Number.parseFloat(
          this.tradeForm.contractSize
        ).toFixed(2);
      }
    },
  },
  watch: {
    viewSelectedTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangedepositTCUsers();
          this.getArrangedepositCurrencies();
          this.getArrangedepositOrderTakenGivenThrough();
          this.getAllExchanges();
          this.getFutureContractSpecs();

          setTimeout(() => {
            if (this.changedInputs.length > 0) {
              // console.log(this.changedInputs)
              this.changedInputs.forEach((i) => {
                if (this.changedValuesForm.hasOwnProperty(_.camelCase(i.key))) {
                  let refKey = _.camelCase(i.key);

                  if (this.$refs[refKey]) {
                    if (
                      this.$refs[refKey].$el.classList.length > 0 ||
                      this.$refs[refKey].$el.classList.indexOf("v-select") == 1
                    ) {
                      this.$refs[refKey].$el.classList.add(
                        "change_selectbox_style"
                      ); // for v-select box
                    }

                    this.$refs[refKey].$el.style = "color:orange";
                  }
                }
              });
            }
          }, 500);
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,
            client: val.Client ?? null,
            clientAccount: val.ClientAcc ?? null,
            counterparty: val.Cpty ?? null,
            cptyAccount: val.CptyAcc ?? null,
            contractName: val.ContractName ?? null,
            numberOfContracts:
              val.NumberOfContracts != null
                ? Number(val.NumberOfContracts)
                : null,
            contractSize:
              val.ContractSize != null ? parseFloat(val.ContractSize) : null,
            contractCcy: val.Ccy ?? null,
            clientPrice:
              val.ClientPrice != null ? parseFloat(val.ClientPrice) : null,
            executionPrice:
              val.ExecutionPrice != null
                ? parseFloat(val.ExecutionPrice)
                : null,
            exchangeFee:
              val.ExchangeFee != null ? parseFloat(val.ExchangeFee) : null,
            expiryDate:
              val.ExpiryDate != null ? this.dateFormat(val.ExpiryDate) : null,
            exchangeTraded: val.ExchangeTraded ?? null,
            ticker: val.Ticker ?? null,
            collPerContract:
              val.CollPerContract != null
                ? parseFloat(val.CollPerContract)
                : null,
            collTotal: val.CollTotal != null ? parseFloat(val.CollTotal) : null,
            clientCommPerContract:
              val.ClientCommPerContract != null
                ? parseFloat(val.ClientCommPerContract)
                : null,
            clientCommTotal:
              val.ClientCommTotal != null
                ? parseFloat(val.ClientCommTotal)
                : null,
            cptyCommPerContract:
              val.CptyCommPerContract != null
                ? parseFloat(val.CptyCommPerContract)
                : null,
            cptyCommTotal:
              val.CptyCommTotal != null ? parseFloat(val.CptyCommTotal) : null,
            netCommission: val.NetComm != null ? parseFloat(val.NetComm) : null,
            spreadGain:
              val.SpreadGain != null ? parseFloat(val.SpreadGain) : null,
            totalTcProfit:
              val.TotalTcProfit != null ? parseFloat(val.TotalTcProfit) : null,
            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null ? val.OrderDurationTime : null,
            entryType: val.EntryType ?? null,
            buySell: val.CptyBuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime: val.OrderTime != null ? val.OrderTime : null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettDate != null ? this.dateFormat(val.SettDate) : null,
            noticeDate:
              val.NoticeDate != null ? this.dateFormat(val.NoticeDate) : null,
            executionTime: val.ExecTime != null ? val.ExecTime : null,
            counterpartyTrader: val.CptyTrader ?? null,
            scfUser: val.TcUser ?? null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            actingCapaticy: val.ActingCap ?? null,
            counterpartyRef: val.CpRef ?? null,
            hedgeSpec: val.HedgeSpec ?? null,
            settlementType: val.SettlementType ?? null,
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            note: val.TraderNote ?? null,
            confirmationDate:
              val.ConfirmationDate != null
                ? this.dateFormat(val.ConfirmationDate)
                : null,
            confirmationTime:
              val.ConfirmationTime != null ? val.ConfirmationTime : null,
            confirmationTrader: val.ConfirmationTrader ?? null,
            confirmationDateClient:
              val.ConfirmationDateClient != null
                ? this.dateFormat(val.ConfirmationDateClient)
                : null,
            confirmationTimeClient:
              val.ConfirmationTimeClient != null
                ? val.ConfirmationTimeClient
                : null,
            cptyUti: val.CptyUti ?? null,
            tcUti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            isin: val.Isin ?? null,
            tradeRef: val.id,
          };

          this.changeTimeInForceType();
          this.copyCurrencies();

          //set trade && settle date && expiry date name
          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.settlementDate)
          );
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          let expiryDate = new Date(
            this.MDYdateFormat(this.tradeForm.expiryDate)
          );
          this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

          let noticeDate = new Date(
            this.MDYdateFormat(this.tradeForm.noticeDate)
          );
          this.tradeForm.noticeDateName = this.weekday[noticeDate.getDay()];

          this.arrangeInputFormats();

          // hack textare row count
          setTimeout(function() {
            let textarea = document.getElementById("form-textarea");
            if (textarea) {
              textarea.rows = 1;
            }
          }, 200);
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

.expiryDateName .input-group-append .input-group-text {
  color: red;
}

.noticeDateName .input-group-append .input-group-text {
  color: red;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change_selectbox_style {
  .vs__dropdown-toggle {
    .vs__selected-options {
      .vs__selected {
        color: orange;
      }
    }
  }
}
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";
</style>
