<template>
  <div>
    {{ /*enter new trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="enterNewTradeModal"
      :title="$t('deposit.titles.enter_new_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('deposit.modals.create_trade_modal_ok')"
      :cancel-title="$t('deposit.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal "
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                  <b-card
                    :title="
                      $t('deposit.titles.client_counterparty_details')
                    "
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('deposit.trade_form.client')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.client')"
                            rules="required"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.client"
                                ref="client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t('deposit.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selected="selectedClient"
                                class="compact_vue-select"
                                style="width: 100%"
                              />
                              <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                                class="input-dropdown animate__animated animate__fadeIn"
                                v-if="searchedClients.length > 0"
                              >
                                <template #button-content class="p-1">
                                  <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                  />
                                </template>

                                <b-dropdown-item
                                  @click="seeFilteredClientModal"
                                >
                                  <feather-icon icon="EyeIcon" />
                                  <span class="align-middle ml-50">{{
                                    $t("general_text.see_verified_clients")
                                  }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                       
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.client_account')"
                            rules="required"
                          >
                            <b-form-input
                              id="clientAccount"
                              ref="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('deposit.trade_form.client_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                       
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.counterparty')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.counterparty')"
                            rules="required"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.counterparty"
                                ref="Counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'deposit.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selected="selectedCounterparty"
                                class="compact_vue-select"
                                style="width: 100%;"
                              />
                              <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                                class="input-dropdown animate__animated animate__fadeIn"
                                v-if="searchedCounterparties.length > 0"
                              >
                                <template #button-content class="p-1">
                                  <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                  />
                                </template>

                                <b-dropdown-item
                                  @click="seeFilteredCounterpartyModal"
                                >
                                  <feather-icon icon="EyeIcon" />
                                  <span class="align-middle ml-50">{{
                                    $t("general_text.see_verified_clients")
                                  }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                       
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.cpty_account')"
                            rules="required"
                          >
                            <b-form-input
                              id="CounterpartyAccount"
                              ref="CounterpartyAccount"
                              v-model="tradeForm.counterpartyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('deposit.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Order Details***************************************************************** */ -->
                  <b-card :title="$t('deposit.titles.order_details')">
                    <b-row>
                      <!-- Instruction date-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('deposit.trade_form.instructionDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.instructionDate')"
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('deposit.trade_form.instructionDate')
                              "
                              v-model="tradeForm.instructionDate"
                              onClose="testClose"
                              class="form-control "
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkInstructionDate('Instruction Date', true)"
                              ref="instructionDate"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- instruction time-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.instructionTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.instructionTime')"
                            rules="required|min:8"
                          >
                            <cleave
                              id="instructionTime"
                              ref="instructionTime"
                              v-model="tradeForm.instructionTime"
                              class="form-control "
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('deposit.trade_form.instructionTime')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkInstructionTime()"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party trader -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('deposit.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('deposit.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              ref="counterpartyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'deposit.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              class="compact_vue-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- sfc tc user -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.sfc_user')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              ref="tcUser"
                              :options="optScfUsers"
                              :clearable="false"
                              :placeholder="
                                $t('deposit.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      
                      <!-- cost center -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('deposit.trade_form.cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              ref="CostCenter"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- order taken via -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.order_taken_via')"
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              ref="orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'deposit.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken through -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="
                            $t('deposit.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('deposit.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              ref="orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'deposit.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Trader Note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('deposit.trade_form.note')"
                              no-resize
                              v-model="tradeForm.traderNote"
                              ref="traderNote"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>


                    </b-row>
                  </b-card>

                   <!-- /******************************* Settlement Details***************************************************************** */ -->
                   <b-card :title="$t('deposit.titles.settlement_details')">
                    <b-row>
                       <!-- Tc Uti  -->
                       <b-col cols="12" xl="4" lg="4" md="4" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('deposit.trade_form.TcUti')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Couterparty Ref  -->
                      <b-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                       
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.CounterpartyRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.CounterpartyRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CounterpartyRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('deposit.trade_form.CounterpartyRef')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                     
                      <!-- Unique Link ID -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                       
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkId"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkId"
                              type="number"
                              name="uniqueLinkId"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('deposit.trade_form.UniqueLinkId')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                </b-col>

                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /*******************************Contract Details***************************************************************** */ -->
                  <b-card :title="$t('deposit.titles.fx_details')">
                    <b-row>
                      <!-- product Type -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('deposit.trade_form.ProductType')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'deposit.trade_form.ProductType'
                              )
                            "
                            rules="required"
                          >
                              <v-select
                                ref="productType"
                                v-model="tradeForm.productType"
                                :options="optProductTypes"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'deposit.trade_form.ProductType'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="false"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedProductType(tradeForm.productType)
                                "
                              />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- deposit amount -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.DepositAmount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.DepositAmount')"
                            rules="required"
                          >
                            <cleave
                              ref="depositAmount"
                              id="depositAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.depositAmount"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('deposit.trade_form.DepositAmount')
                              "
                              @blur.native="
                                calculateDepositAmount(tradeForm.depositAmount)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- deposit currency ccy -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="
                            $t('deposit.trade_form.DepositCcy')
                          "
                         
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('deposit.trade_form.DepositCcy')
                            "
                             rules="required"
                          >
                            <v-select
                              ref="ccy"
                              v-model="tradeForm.depositCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('deposit.trade_form.DepositCcy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedDepositCcy(tradeForm.depositCcy)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Counterparty bank Rate -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('deposit.trade_form.CounterpartyRate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.CounterpartyRate')"
                            :rules="'required|min_value:0'
                            "
                          >


                            <b-input-group ref="groupAppend" prepend="%" class="compact_form_input-group">
                              <cleave
                                  id="counterpartyRate"
                                  ref="counterpartyRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.counterpartyRate"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('deposit.trade_form.CounterpartyRate')
                                  "
                                  @blur.native="changeCounterpartyRate(tradeForm.counterpartyRate)"
                                />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                       <!-- Client Rate -->
                       <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('deposit.trade_form.ClientRate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.ClientRate')"
                            :rules="'required|min_value:0'
                            "
                          >


                            <b-input-group ref="groupAppend" prepend="%" class="compact_form_input-group">

                              <cleave
                                  id="clientRate"
                                  ref="clientRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientRate"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('deposit.trade_form.ClientRate')
                                  "
                                  @blur.native="changeClientRate(tradeForm.counterpartyRate)"
                                />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Start Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6">
                        <b-form-group
                          :label="$t('deposit.trade_form.StartDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.StartDate')"
                            rules="required"
                          >
                            <b-input-group
                              :class="
                                tradeForm.startDateName == 'Sunday' ||
                                tradeForm.startDateName == 'Saturday'
                                  ? 'startDateName'
                                  : ''
                              "
                              :append="tradeForm.startDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('deposit.trade_form.StartDate')
                                "
                                v-model="tradeForm.startDate"
                                ref="startDate"
                                onClose="testClose"
                                class="form-control"
                                :config="startDateConfig"
                                @on-change="checkDepositDate('Start Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- T+ -->
                      <b-col
                        cols="6"
                        xl="1"
                        lg="1"
                        md="2"
                        sm="6"
                        style="padding-left: 0 !important; padding-right:0.5rem !important"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.Tplus"
                              name="T+"
                              placeholder="T+"
                              @change="arrangeTPlus"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- End Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6"
                      >
                        <b-form-group
                          :label="$t('deposit.trade_form.EndDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.EndDate')"
                          >
                            <b-input-group
                              :class="
                                tradeForm.endDateName == 'Sunday' ||
                                tradeForm.endDateName == 'Saturday'
                                  ? 'endDateName'
                                  : ''
                              "
                              :append="dayCount != null ? tradeForm.endDateName + ' (' + dayCount +' days)' : tradeForm.endDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('deposit.trade_form.EndDate')
                                "
                                v-model="tradeForm.endDate"
                                ref="endDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  checkDepositDate('End Date')
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Day Count Basis -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                      >
                        <b-form-group
                          :label="
                            $t('deposit.trade_form.DayCountBasis')
                          "
                          rules="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('deposit.trade_form.DayCountBasis')
                            "
                          >
                            <v-select
                              ref="dayCountBasis"
                              v-model="tradeForm.dayCountBasis"
                              :options="optDayCountBasises"
                              :clearable="false"
                              :placeholder="
                                $t('deposit.trade_form.DayCountBasis')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedDayCountBasis(tradeForm.depositCcy)
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Bank End Amount -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                        >
                        <b-form-group
                          :label="$t('deposit.trade_form.BankEndAmount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.BankEndAmount')"
                            :rules="'required'
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.bankEndAmountCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="bankEndAmount"
                                id="bankEndAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.bankEndAmount"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('deposit.trade_form.BankEndAmount')
                                "
                                @blur.native="
                                  calculateBankEndAmount(
                                    tradeForm.bankEndAmount
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client End Amount -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                        >
                        <b-form-group
                          :label="$t('deposit.trade_form.ClientEndAmount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.ClientEndAmount')"
                            :rules="'required'
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.clientEndAmountCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="clientEndAmount"
                                id="clientEndAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientEndAmount"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('deposit.trade_form.ClientEndAmount')
                                "
                                @blur.native="
                                  calculateClientEndAmount(
                                    tradeForm.clientEndAmount
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Bank Interest Total -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" 
                        >
                        <b-form-group
                          :label="$t('deposit.trade_form.BankInterestTotal')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.BankInterestTotal')"
                            :rules="'required'
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.bankInterestTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="bankInterestTotal"
                                id="bankInterestTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.bankInterestTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('deposit.trade_form.BankInterestTotal')
                                "
                                @blur.native="
                                  calculateBankInterestTotal(
                                    tradeForm.bankInterestTotal
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      
                      <!-- Client Interest Total -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" 
                        >
                        <b-form-group
                          :label="$t('deposit.trade_form.ClientInterestTotal')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.ClientInterestTotal')"
                            :rules="'required'
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.clientInterestTotalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="clientInterestTotal"
                                id="clientInterestTotal"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientInterestTotal"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('deposit.trade_form.ClientInterestTotal')
                                "
                                @blur.native="
                                  calculateClientInterestTotal(
                                    tradeForm.clientInterestTotal
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Accrued -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                        >
                        <b-form-group
                          :label="$t('deposit.trade_form.ClientAccrued')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.ClientAccrued')"
                            :rules="'required'
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.clientAccruedCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="clientAccrued"
                                id="clientAccrued"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientAccrued"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('deposit.trade_form.ClientAccrued')
                                "
                                @blur.native="
                                  calculateClientAccrued(
                                    tradeForm.clientAccrued
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Tc Profit -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6"
                        >
                        <b-form-group
                          :label="$t('deposit.trade_form.TcProfit')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('deposit.trade_form.TcProfit')"
                            :rules="'required'
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.tcProfitCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="tcProfit"
                                id="tcProfit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.tcProfit"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('deposit.trade_form.TcProfit')
                                "
                                @blur.native="
                                  calculateTcProfit(
                                    tradeForm.tcProfit
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" >
                        <hr>
                      </b-col>

                      
                                <!-- // broken inputs -->
                        <!-- check Broken-->
                        <b-col cols="12" xl="3" lg="3" md="3" sm="6" class=" mt-1">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('deposit.trade_form.Broken')"
                            >
                              <b-form-checkbox
                                v-model="tradeForm.broken"
                                plain
                                @change="changeBroken(tradeForm.broken)"
                                class="compact_form_checkbox"
                              >
                                {{ $t("deposit.trade_form.Broken") }}
                              </b-form-checkbox>
                              <small class="text-danger ">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Broken Date -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6" v-if="tradeForm.broken">
                          <b-form-group
                            :label="$t('deposit.trade_form.BrokenDate')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('deposit.trade_form.BrokenDate')"
                              rules="required"
                            >
                              <b-input-group
                                :class="
                                  tradeForm.brokenDateName == 'Sunday' ||
                                  tradeForm.brokenDateName == 'Saturday'
                                    ? 'brokenDateName'
                                    : ''
                                "
                                :append="tradeForm.brokenDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t('deposit.trade_form.BrokenDate')
                                  "
                                  v-model="tradeForm.brokenDate"
                                  ref="brokenDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="checkBrokenDate()"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Broken Accrued -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6" v-if="tradeForm.broken"
                          >
                          <b-form-group
                            :label="$t('deposit.trade_form.BrokenAccrued')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('deposit.trade_form.BrokenAccrued')"
                              :rules="'required'
                              "
                            >
                              <b-input-group
                                ref="groupAppend"
                                :append="tradeForm.brokenAccruedCcy"
                                class="compact_form_input-group"
                              >
                                <cleave
                                  ref="brokenAccrued"
                                  id="brokenAccrued"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.brokenAccrued"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('deposit.trade_form.BrokenAccrued')
                                  "
                                  @blur.native="
                                    calculateBrokenAccrued(
                                      tradeForm.brokenAccrued
                                    )
                                  "
                                  :disabled="true"
                                />
                              </b-input-group>

                              <small class="text-danger ">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Broken Fee -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6" v-if="tradeForm.broken"
                          >
                          <b-form-group
                            :label="$t('deposit.trade_form.BrokenFee')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('deposit.trade_form.BrokenFee')"
                              :rules="'required'
                              "
                            >
                              <b-input-group
                                ref="groupAppend"
                                :append="tradeForm.brokenFeeCcy"
                                class="compact_form_input-group"
                              >
                                <cleave
                                  ref="brokenFee"
                                  id="brokenFee"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.brokenFee"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('deposit.trade_form.BrokenFee')
                                  "
                                  @blur.native="
                                    calculateBrokenFee(
                                      tradeForm.brokenFee
                                    )
                                  "
                                  :disabled="true"
                                />
                              </b-input-group>

                              <small class="text-danger ">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                    </b-row>
                  </b-card>
                 
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("deposit.overlay_messages.creating_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- save default entry -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingDefaultEntry"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                v-model="showDefaultEntry"
                class="float-right compact_form_checkbox"
                style="margin-right:0.8rem"
                plain
              >
                {{ $t("general_title.default_entry_title") }}
              </b-form-checkbox>
              <validation-observer
                ref="tradeDefaultEntryValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showDefaultEntry ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showDefaultEntry"
                    id="defaultEntryName"
                    v-model="defaultEntryName"
                    name="defaultEntryName"
                    class="float-right animate__animated animate__fadeIn compact_form-input"
                    type="text"
                    :placeholder="$t('general_title.default_entry_name')"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                @click="saveDefaultEntry"
                v-if="showDefaultEntry"
                variant="gradient-info"
                size="sm"
                class="animate__animated animate__fadeIn modal-btn"
              >
                {{ $t("general_title.default_entry_title") }}
              </b-button>
            </b-overlay>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center mobile-footer"
          >
            <b-dropdown
              :text="$t('general_title.default_entries')"
              dropup
              right
              class="ml-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="optDefaultEntries.length > 0"
            >
              <b-dropdown-item
                v-for="(d, index) in optDefaultEntries"
                :key="index"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-1"
                  style="color:red;"
                  @click="deleteSavedDefault(d.id)"
                  icon="XIcon"
                  size="12"
                />
                <span
                  @click="selectDefaultData(d)"
                  style="font-size:0.8rem;font-weight: 400;"
                >
                  {{ d.DefaultEntryName }}
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetTradeModal"
            >
              {{ $t("deposit.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="tradeOkOperation"
            >
              {{ $t("deposit.modals.create_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** enter new trade modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('deposit.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('deposit.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import depositStoreModule from "./depositStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
      const deposit_APP_STORE_MODULE_NAME = "deposit";
      // Register module
      if (!store.hasModule(deposit_APP_STORE_MODULE_NAME))
        store.registerModule(
          deposit_APP_STORE_MODULE_NAME,
          depositStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(deposit_APP_STORE_MODULE_NAME))
          store.unregisterModule(deposit_APP_STORE_MODULE_NAME);
      });
    },

  mounted() {},
  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      enterNewTradeModal: false,
      tradeModalLoading: false,
      loadingClient: false,
      loadingCounterparty: false,
      showDefaultEntry: false,
      defaultEntryName: null,
      loadingDefaultEntry: false,
      optDefaultEntries: [],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optProductTypes: ["TIME DEPOSIT","COLLATERAL DEPOSIT","CLIENT POOL","CLIENT POOL COLLATERAL"],
      optPriceTypes: [],
      optDayCountBasises: [360,365],
      optExchangeTrades: [],
      optScfUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      optCounterPartyTraders: [],
      fetchedTradeData: {},
      dayCount:null,
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        counterpartyAccount: null,
        //fx details
        productType: null,
        depositAmount: null,
        depositCcy: null,
        counterpartyRate:null,
        clientRate:null,
        startDate: null,
        startDateName: null,
        defStartDateName: null,
        Tplus: null,
        endDate: null,
        endDateName: null,
        dayCountBasis:null,
        bankEndAmount:null,
        bankEndAmountCcy:null,
        clientEndAmount:null,
        clientEndAmountCcy:null,
        bankInterestTotal:null,
        bankInterestTotalCcy:null,
        clientInterestTotal:null,
        clientInterestTotalCcy:null,
        clientAccrued:null,
        clientAccruedCcy:null,
        tcProfit:null,
        tcProfitCcy:null,
        broken:false,
        brokenDate:null,
        brokenDateName:null,
        brokenAccrued:null,
        brokenAccruedCcy:null,
        brokenFee:null,
        brokenFeeCcy:null,
        //order details
        instructionDate: moment().format("DD-MM-YYYY"),
        instructionDateName: null,
        instructionTime: moment().format("HH:mm:ss"),
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        traderNote: null,
        //settle details
        tcUti: null,
        uniqueLinkId: null,
        counterpartyRef: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      startDateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.defStartDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.defStartDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.defStartDateName = "Tomorrow";
                  break;
              }

              fp.setDate(date);
            },
          }),
        ],
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchdeposit"]),
    saveDefaultEntry() {
      this.$refs.tradeDefaultEntryValidation.validate().then((success) => {
        if (success) {
          // set will be save default entry data
          if (this.showDefaultEntry) {
            this.loadingDefaultEntry = true;

            var defaultEntryData = {
              DefaultEntryName: this.defaultEntryName,
              DefaultEntryData: this.tradeForm,
              DefaultType: "Deposit",
              UserId: this.user.id,
            };

            store
              .dispatch("deposit/saveDefaultEntry", {
                defaultEntryData: this.showDefaultEntry
                  ? defaultEntryData
                  : null,
              })
              .then((res) => {
                if (res.data.info == "success") {
                  this.defaultEntryName = null;
                  this.saveDefaultEntrySuccessToastMessage();
                  this.getSavedDefaultEntries();
                  this.loadingDefaultEntry = false;
                } else if (res.data.info == "existing") {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                } else {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },
    selectDefaultData(data) {
      if (data.DefaultEntryData) {
        this.tradeForm = data.DefaultEntryData;

        if (
          data.DefaultEntryData.defStartDateName &&
          data.DefaultEntryData.defStartDateName != null
        ) {
          if (data.DefaultEntryData.defStartDateName == "Yesterday") {
            this.tradeForm.startDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.defStartDateName == "Tomorrow") {
            this.tradeForm.startDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.defStartDateName == "Today") {
            this.tradeForm.startDate = this.dateFormat(Date.now());
          }
        }
      }
    },

    getSavedDefaultEntries() {
      store
        .dispatch("deposit/getSavedDefaultEntries", {
          DefaultType: "Deposit",
          UserId: this.user.id,
        })
        .then((res) => {
          this.optDefaultEntries = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteSavedDefault(id) {
      store
        .dispatch("deposit/deleteSavedDefault", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedDefaultEntries();
          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    getArangeDepositTCUsers() {
      store
        .dispatch("deposit/fetchArangeDepositTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.optPriceTypes = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("deposit.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeDepositOrderTakenGivenThrough() {
      store
        .dispatch(
          "deposit/fetchArrangeDepositOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(
              this.$t("deposit.messages.not_found_given_order_data")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangedepositCurrencies() {
      store
        .dispatch("deposit/fetchArrangeDepositCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("deposit", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;

              //set and check trade data
              let tradeData = this.setTradeData();

              store
                .dispatch("deposit/createNewEntry", {
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.enterNewTradeModal = false;
                    this.$emit("createddeposit", true);
                    this.createTradeToastMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },

    //********************************************** client && counterpary section ***********************/
    selectedClient(search) {
      this.tradeForm.clientAccount = null;
      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
      this.tradeForm.clientCommPerContract =
        selecting.ClientCommPerContract != null
          ? parseFloat(selecting.ClientCommPerContract)
          : null;

      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = Number.parseFloat(
          this.tradeForm.clientCommPerContract
        ).toFixed(2);
      }
    },

    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.tradeForm.clientCommPerContract =
        selecting.ClientCommPerContract != null
          ? parseFloat(selecting.ClientCommPerContract)
          : null;

      if (this.tradeForm.clientCommPerContract != null) {
        this.tradeForm.clientCommPerContract = Number.parseFloat(
          this.tradeForm.clientCommPerContract
        ).toFixed(2);
      }
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    selectedCounterparty(search) {
      //this.tradeForm.counterparty = null;
      this.tradeForm.counterpartyAccount = null;
      this.tradeForm.counterpartyTrader = null;
      this.optCounterPartyTraders = [];

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.counterpartyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.counterpartyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.counterpartyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },

    //********************************************** END client && counterpary section ******************/

    //********************************************** order details ***********************/
    checkInstructionDate(type, defDateName = false) {
      if (type == "Instruction Date") {
        if (defDateName) {
          this.tradeForm.instructionDateName = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.instructionDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.startDate))
        ) {
          this.warningMessage(
            this.$t("deposit.messages.start_date_after_instruction_date")
          );
          // this.$refs.instructionDate.fp.clear(); // clear flat picker data
          this.tradeForm.instructionDate = null;
        }
      }
    },
    

    checkInstructionTime(type) {
        if (
          this.tradeForm.instructionTime != null &&
          this.tradeForm.instructionTime.length < 8
        ) {
          for (let i = this.tradeForm.instructionTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.instructionTime += ":";
            } else if (i == 5) {
              this.tradeForm.instructionTime += ":";
            } else {
              this.tradeForm.instructionTime += "0";
            }
          }
        }
       
    },

    //********************************************** END order details ******************/

    //********************************************** fx details ***********************/
    
    selectedProductType(productType) {
      
    },
    
    calculateDepositAmount(value) {
      this.convertNumberInputFormat();
      this.depositCalculations();
      this.arrangeInputFormats();
    },

    selectedDepositCcy(ccy) {
      this.copyCurrencies();
    },

    copyCurrencies() {
      let ccy = JSON.parse(JSON.stringify(this.tradeForm.depositCcy));
      this.tradeForm.bankEndAmountCcy = ccy;
      this.tradeForm.clientEndAmountCcy = ccy;
      this.tradeForm.bankInterestTotalCcy = ccy;
      this.tradeForm.clientInterestTotalCcy = ccy;
      this.tradeForm.clientAccruedCcy = ccy;
      this.tradeForm.tcProfitCcy = ccy;
      if(this.tradeForm.broken) {
        this.tradeForm.brokenAccruedCcy = ccy;
        this.tradeForm.brokenFeeCcy = ccy;
      }
      
    },

    changeCounterpartyRate() {
      this.convertNumberInputFormat();
      this.depositCalculations();
      this.arrangeInputFormats();
    },

    changeClientRate() {
      this.convertNumberInputFormat();
      this.depositCalculations();
      this.arrangeInputFormats();
    },

    checkDepositDate(type, defDateName = false) {
      if (type == "Start Date" && this.tradeForm.startDate != null) {
        if (defDateName) {
          this.defStartDateName = null;
        }
        let startDate = new Date(this.MDYdateFormat(this.tradeForm.startDate));

        //set start date name
        this.tradeForm.startDateName = this.weekday[startDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.startDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.endDate))
        ) {
          this.warningMessage(
            this.$t("deposit.messages.start_date_after_end_date")
          );
          // this.$refs.startDate.fp.clear();
          this.tradeForm.startDate = null;
        }


        if(this.tradeForm.broken && this.tradeForm.brokenDate != null){
          if (
          Date.parse(this.YMDdateFormat(this.tradeForm.brokenDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.startDate))
          ) {
            this.warningMessage(
              this.$t("deposit.messages.start_date_after_broken_date")
            );
            // this.$refs.startDate.fp.clear();
            this.tradeForm.startDate = null;
          }
        }

       
      } else if (type == "End Date" && this.tradeForm.endDate != null) {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.endDate)
        );

        //set end date name
        this.tradeForm.endDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.startDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.endDate))
        ) {
          this.warningMessage(
            this.$t("deposit.messages.start_date_after_end_date")
          );
          // this.$refs.endDate.fp.clear();
          this.tradeForm.endDate = null;
        }

        
      }

      if(this.tradeForm.startDate != null && this.tradeForm.endDate != null) {
        this.convertNumberInputFormat();
        this.depositCalculations();
        this.arrangeInputFormats();
      }
    },

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, startDate) {
      let checkDate = startDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(startDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (
        this.tradeForm.startDate != null &&
        this.tradeForm.startDateName != null
      ) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let startDate = new Date(
                this.MDYdateFormat(this.tradeForm.startDate)
              );

              let period = this.tradeForm.Tplus;
              startDate.setDate(startDate.getDate() + period);

              this.checkHolidaysDate(holidays, startDate);

              //found end date name
              let endDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend end date
              if (endDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (endDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set end date
              this.tradeForm.endDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.endDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let startDate = new Date(
                this.MDYdateFormat(this.tradeForm.startDate)
              );

              let period = this.tradeForm.Tplus;

              startDate.setDate(startDate.getDate() + period);

              //found end date name
              let endDateName = this.weekday[startDate.getDay()];

              //check weekend end date
              if (endDateName == "Saturday") {
                startDate.setDate(startDate.getDate() + 2);
              } else if (endDateName == "Sunday") {
                startDate.setDate(startDate.getDate() + 1);
              }

              // set end date
              this.tradeForm.endDate = this.dateFormat(startDate);
              this.tradeForm.endDateName = this.weekday[
                startDate.getDay()
              ];
            }
          });
        }
      }
    },

    selectedDayCountBasis() {
      this.convertNumberInputFormat();
      this.depositCalculations();
      this.arrangeInputFormats();
    },


    calculateBankEndAmount(value) {
    },
    calculateClientEndAmount(value) {

    },
    calculateBankInterestTotal(value) {

    },
    calculateClientInterestTotal(value) {

    },
    calculateClientAccrued(value) {

    },
    calculateTcProfit(value) {

    },
    changeBroken(broken){
      if(broken && this.tradeForm.depositCcy != null){
        let ccy = JSON.parse(JSON.stringify(this.tradeForm.depositCcy));
        this.tradeForm.brokenAccruedCcy = ccy;
        this.tradeForm.brokenFeeCcy = ccy;
      }

      if(!broken) {
        this.tradeForm.brokenDate = null;
        this.tradeForm.brokenDateName = null;
        // this.$refs.brokenDate.fp.clear();
        this.tradeForm.brokenAccrued = null;
        this.tradeForm.brokenAccruedCcy = null;
        this.tradeForm.brokenFee = null;
        this.tradeForm.brokenFeeCcy = null;
      } else {
        this.convertNumberInputFormat();
        this.depositCalculations();
        this.arrangeInputFormats();
      }
    },
    checkBrokenDate() {
      if(this.tradeForm.broken && this.tradeForm.startDate != null){
          if (
          Date.parse(this.YMDdateFormat(this.tradeForm.brokenDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.startDate)) ||
          Date.parse(this.YMDdateFormat(this.tradeForm.brokenDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.endDate)) 
          ) {
            this.warningMessage(
              this.$t("deposit.messages.broken_date_btwn_start_end_date")
            );
            // this.$refs.startDate.fp.clear();
            this.tradeForm.brokenDate = null;
          }
        }
        
        if(this.tradeForm.brokenDate != null) {
          let brokenDate = new Date(
            this.MDYdateFormat(this.tradeForm.brokenDate)
          );
          
          this.tradeForm.brokenDateName = this.weekday[brokenDate.getDay()];
        }

        this.convertNumberInputFormat();
        this.depositCalculations();
        this.arrangeInputFormats();
      
    },
    calculateBrokenAccrued(value) {

    },
    calculateBrokenFee(value) {

    },


//********************************************** END fx details ***********************/


//**********************************************  Calculate func. ***********************/

    depositCalculations() {

        if(!this.tradeForm.broken) {
          this.tradeForm.brokenDate = null;
          this.tradeForm.brokenDateName = null;
          this.tradeForm.brokenAccrued = null;
          this.tradeForm.brokenAccruedCcy = null;
          this.tradeForm.brokenFee = null;
          this.tradeForm.brokenFeeCcy = null;

          // calculate with counterparty rate
          if(this.tradeForm.depositAmount != null && 
             this.tradeForm.counterpartyRate != null &&
             this.tradeForm.startDate != null &&
             this.tradeForm.endDate != null &&
             this.tradeForm.dayCountBasis != null 
             ) {
              
              // calculate between two days count
              this.dayCount = null;
              this.dayCount = this.calculateBetweenTwoDaysCount(this.tradeForm.startDate,this.tradeForm.endDate);

              this.tradeForm.bankInterestTotal = this.tradeForm.depositAmount * this.tradeForm.counterpartyRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
              this.tradeForm.bankEndAmount = this.tradeForm.depositAmount + this.tradeForm.depositAmount * this.tradeForm.counterpartyRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
             } else {
              this.tradeForm.bankInterestTotal = null;
              this.tradeForm.bankEndAmount = null;
             }

          // calculate with client rate
          if(this.tradeForm.depositAmount != null && 
          this.tradeForm.clientRate != null &&
          this.tradeForm.startDate != null &&
          this.tradeForm.endDate != null &&
          this.tradeForm.dayCountBasis != null 
          ) {
          // calculate between two days count
          this.dayCount = null;
          this.dayCount = this.calculateBetweenTwoDaysCount(this.tradeForm.startDate,this.tradeForm.endDate);

          this.tradeForm.clientInterestTotal = this.tradeForm.depositAmount * this.tradeForm.clientRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
          this.tradeForm.clientEndAmount = this.tradeForm.depositAmount + this.tradeForm.depositAmount * this.tradeForm.clientRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
          
          if(Date.parse(this.YMDdateFormat(this.tradeForm.endDate)) <
            Date.parse(this.YMDdateFormat(moment().format("DD-MM-YYYY")))
            ){
              this.tradeForm.clientAccrued = this.tradeForm.depositAmount * this.tradeForm.clientRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
            
          } else if(
            Date.parse(this.YMDdateFormat(this.tradeForm.startDate)) >
            Date.parse(this.YMDdateFormat(moment().format("DD-MM-YYYY")))
          ) {
            this.tradeForm.clientAccrued = 0;
          } else {
            this.dayCount = null;
            this.dayCount = this.calculateBetweenTwoDaysCount(this.tradeForm.startDate,moment().format("DD-MM-YYYY"));

            this.tradeForm.clientAccrued = this.tradeForm.depositAmount * this.tradeForm.clientRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
          }
          } else {
          this.tradeForm.clientInterestTotal = null;
          this.tradeForm.clientEndAmount = null;
          this.tradeForm.clientAccrued = null;
          }

          //Arrange Day Of Week Caption
          if(this.tradeForm.startDate != null && this.tradeForm.endDate != null) {
            this.dayCount = null;
            this.dayCount = this.calculateBetweenTwoDaysCount(this.tradeForm.startDate,this.tradeForm.endDate);
          } else if(this.tradeForm.endDate != null) {
            this.dayCount = null;
            this.dayCount = this.calculateBetweenTwoDaysCount(moment().format("DD-MM-YYYY"),this.tradeForm.endDate);
          } else {
            this.dayCount = null;
          }
          //

          // calculate tc profit
          if(this.tradeForm.bankEndAmount != null && this.tradeForm.clientEndAmount != null) {
          this.tradeForm.tcProfit = this.tradeForm.bankEndAmount - this.tradeForm.clientEndAmount;
          } else {
            this.tradeForm.tcProfit = null;
          }
        
        } else {
          if(this.tradeForm.depositAmount != null && 
          this.tradeForm.clientRate != null &&
          this.tradeForm.startDate != null &&
          this.tradeForm.brokenDate != null &&
          this.tradeForm.dayCountBasis != null 
          ) {
            this.dayCount = null;
            this.dayCount = this.calculateBetweenTwoDaysCount(this.tradeForm.startDate,this.tradeForm.brokenDate);
            this.tradeForm.brokenAccrued = this.tradeForm.depositAmount * this.tradeForm.clientRate / 100 * (this.dayCount) / Number(this.tradeForm.dayCountBasis);
          } else {
            this.tradeForm.brokenAccrued = null;
          }
          this.tradeForm.bankEndAmount = this.tradeForm.depositAmount;
          this.tradeForm.clientEndAmount = this.tradeForm.depositAmount;
          this.tradeForm.bankInterestTotal = 0;
          this.tradeForm.clientInterestTotal = 0;
          this.tradeForm.clientAccrued = 0;
          this.tradeForm.tcProfit = 0;

        } 
    },
    calculateBetweenTwoDaysCount(date1, date2) {
      let date_1 = new Date(this.MDYdateFormat(date1))
      let date_2 = new Date(this.MDYdateFormat(date2))
      let difference = date_2.getTime() - date_1.getTime() ;
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays;
    },

    convertNumberInputFormat() {

      if (this.tradeForm.depositAmount != null) {
        this.tradeForm.depositAmount = parseFloat(this.tradeForm.depositAmount);
      }

      if (this.tradeForm.clientRate != null) {
        this.tradeForm.clientRate = parseFloat(this.tradeForm.clientRate);
      }

      if (this.tradeForm.counterpartyRate != null) {
        this.tradeForm.counterpartyRate = parseFloat(this.tradeForm.counterpartyRate);
      }

      if (this.tradeForm.bankEndAmount != null) {
        this.tradeForm.bankEndAmount = parseFloat(this.tradeForm.bankEndAmount);
      }

      if (this.tradeForm.clientEndAmount != null) {
        this.tradeForm.clientEndAmount = parseFloat(this.tradeForm.clientEndAmount);
      }

      if (this.tradeForm.bankInterestTotal != null) {
        this.tradeForm.bankInterestTotal = parseFloat(this.tradeForm.bankInterestTotal);
      }

      if (this.tradeForm.clientInterestTotal != null) {
        this.tradeForm.clientInterestTotal = parseFloat(this.tradeForm.clientInterestTotal);
      }

      if (this.tradeForm.clientAccrued != null) {
        this.tradeForm.clientAccrued = parseFloat(this.tradeForm.clientAccrued);
      }

      if (this.tradeForm.tcProfit != null) {
        this.tradeForm.tcProfit = parseFloat(this.tradeForm.tcProfit);
      }

      if (this.tradeForm.brokenAccrued != null) {
        this.tradeForm.brokenAccrued = parseFloat(this.tradeForm.brokenAccrued);
      }

      if (this.tradeForm.brokenFee != null) {
        this.tradeForm.brokenFee = parseFloat(this.tradeForm.brokenFee);
      }

    },
    arrangeInputFormats() {


      if (this.tradeForm.depositAmount != null) {
        this.tradeForm.depositAmount = Number.parseFloat(
          this.tradeForm.depositAmount
        ).toFixed(2);
      }

      if (this.tradeForm.clientRate != null) {
        this.tradeForm.clientRate = Number.parseFloat(
          this.tradeForm.clientRate
        ).toFixed(4);
      }

      if (this.tradeForm.counterpartyRate != null) {
        this.tradeForm.counterpartyRate = Number.parseFloat(
          this.tradeForm.counterpartyRate
        ).toFixed(4);
      }

      if (this.tradeForm.bankEndAmount != null) {
        this.tradeForm.bankEndAmount = Number.parseFloat(
          this.tradeForm.bankEndAmount
        ).toFixed(2);
      }

      if (this.tradeForm.clientEndAmount != null) {
        this.tradeForm.clientEndAmount = Number.parseFloat(
          this.tradeForm.clientEndAmount
        ).toFixed(2);
      }

      if (this.tradeForm.bankInterestTotal != null) {
        this.tradeForm.bankInterestTotal = Number.parseFloat(
          this.tradeForm.bankInterestTotal
        ).toFixed(2);
      }

      if (this.tradeForm.clientInterestTotal != null) {
        this.tradeForm.clientInterestTotal = Number.parseFloat(
          this.tradeForm.clientInterestTotal
        ).toFixed(2);
      }

      if (this.tradeForm.clientAccrued != null) {
        this.tradeForm.clientAccrued = Number.parseFloat(
          this.tradeForm.clientAccrued
        ).toFixed(2);
      }

      if (this.tradeForm.tcProfit != null) {
        this.tradeForm.tcProfit = Number.parseFloat(
          this.tradeForm.tcProfit
        ).toFixed(2);
      }

      if (this.tradeForm.brokenAccrued != null) {
        this.tradeForm.brokenAccrued = Number.parseFloat(
          this.tradeForm.brokenAccrued
        ).toFixed(2);
      }

      if (this.tradeForm.brokenFee != null) {
        this.tradeForm.brokenFee = Number.parseFloat(
          this.tradeForm.brokenFee
        ).toFixed(2);
      }

    },

    
    //********************************************** END  Calculate func. ***********************/

    resetTradeModal() {
      this.checkedHolidayDate = null;
      this.optSecurityDescriptions = [];
      this.optPriceTypes = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
      this.optProductTypes = ["TIME DEPOSIT","COLLATERAL DEPOSIT","CLIENT POOL","CLIENT POOL COLLATERAL"];
      this.showDefaultEntry = false;
      this.dayCount = null;
      //
      this.tradeForm = {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        counterpartyAccount: null,
        //fx details
        productType: null,
        depositAmount: null,
        depositCcy: null,
        counterpartyRate:null,
        clientRate:null,
        startDate: null,
        startDateName: null,
        defStartDateName: null,
        Tplus: null,
        endDate: null,
        endDateName: null,
        dayCountBasis:null,
        bankEndAmount:null,
        bankEndAmountCcy:null,
        clientEndAmount:null,
        clientEndAmountCcy:null,
        bankInterestTotal:null,
        bankInterestTotalCcy:null,
        clientInterestTotal:null,
        clientInterestTotalCcy:null,
        clientAccrued:null,
        clientAccruedCcy:null,
        tcProfit:null,
        tcProfitCcy:null,
        broken:false,
        brokenDate:null,
        brokenDateName:null,
        brokenAccrued:null,
        brokenAccruedCcy:null,
        brokenFee:null,
        brokenFeeCcy:null,
        //order details
        instructionDate: moment().format("DD-MM-YYYY"),
        instructionDateName: null,
        instructionTime: moment().format("HH:mm:ss"),
        counterpartyTrader:null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        traderNote: null,
        //settle details
        tcUti: null,
        uniqueLinkId: null,
        counterpartyRef: null,
      };

      this.enterNewTradeModal = false;
      this.fetchedTradeData = {};
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("deposit.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("deposit.toast_messages.trade_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    saveDefaultEntrySuccessToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("general_title.save_default_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("deposit.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatPrice2(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },
    setTradeData() {
      
      let setTrade = {
        costCenter: this.tradeForm.costCenter,
        Client: this.tradeForm.client != null ? this.tradeForm.client : null,
        ClientAccount:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        Counterparty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        CounterpartyAccount:
          this.tradeForm.counterpartyAccount != null
            ? this.tradeForm.counterpartyAccount
            : null,
        ProductType:
          this.tradeForm.productType != null
            ? this.tradeForm.productType
            : null,
        DepositAmount:
          this.tradeForm.depositAmount != null
            ? parseFloat(this.tradeForm.depositAmount)
            : null,
        Ccy:
          this.tradeForm.depositCcy != null
            ? this.tradeForm.depositCcy
            : null,
        
        CounterpartyRate	:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate)
            : null,
        ClientRate	:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate)
            : null,
        InstructionDate:
          this.tradeForm.instructionDate != null
            ? this.YMDdateFormat(this.tradeForm.instructionDate)
            : null,
        InstructionTime:
          this.tradeForm.instructionTime != null
            ? this.timeFormat(this.tradeForm.instructionTime)
            : null,
        CounterpartyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        TcUser: this.tradeForm.scfUser != null ? this.tradeForm.scfUser : null,
        OrderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        OrderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        TraderNote: this.tradeForm.traderNote != null ? this.tradeForm.traderNote : null,
        UniqueLinkId:
          this.tradeForm.uniqueLinkId != null
            ? this.tradeForm.uniqueLinkId
            : null,
        StartDate:
          this.tradeForm.startDate != null
            ? this.YMDdateFormat(this.tradeForm.startDate)
            : null,
        EndDate:
          this.tradeForm.endDate != null
            ? this.YMDdateFormat(this.tradeForm.endDate)
            : null,
        DayCountBasis:
          this.tradeForm.dayCountBasis != null
            ? this.tradeForm.dayCountBasis
            : null,
        BankEndAmount:
          this.tradeForm.bankEndAmount != null
            ? parseFloat(this.tradeForm.bankEndAmount)
            : null,
        ClientEndAmount:
          this.tradeForm.clientEndAmount != null
            ? parseFloat(this.tradeForm.clientEndAmount)
            : null,
        BankInterestTotal:
          this.tradeForm.bankInterestTotal != null
            ? parseFloat(this.tradeForm.bankInterestTotal)
            : null,
        ClientInterestTotal:
          this.tradeForm.clientInterestTotal != null
            ? parseFloat(this.tradeForm.clientInterestTotal)
            : null,
        ClientAccrued:
          this.tradeForm.clientAccrued != null
            ? parseFloat(this.tradeForm.clientAccrued)
            : null,
        TcProfit:
          this.tradeForm.tcProfit != null
            ? parseFloat(this.tradeForm.tcProfit)
            : null,
        BrokenDate:
          this.tradeForm.brokenDate != null
            ? this.YMDdateFormat(this.tradeForm.brokenDate)
            : null,
        BrokenAccrued:
          this.tradeForm.brokenAccrued != null
            ? parseFloat(this.tradeForm.brokenAccrued)
            : null,
        BrokenFee:
          this.tradeForm.brokenFee != null
            ? parseFloat(this.tradeForm.brokenFee)
            : null,
        Broken: this.tradeForm.broken


        // savedBy: this.user.name ? this.user.name + ' ' + this.user.surname : null,
        // saveDate: moment().format("YYYY-MM-DD"),
        // saveTime: moment().format("HH:mm:ss"),
      };

      return setTrade;
    },
  },
  watch: {
    // "tradeForm.executionPriceCcy": {
    //   handler: function(val, before) {
    //     console.log(val)
    //   },
    //   deep: true,
    // },
    showDefaultEntry: {
      handler: function(val, before) {
        if (!val) {
          this.defaultEntryName = null;
        }
      },
    },
    enterNewTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeDepositTCUsers();
          this.getArrangedepositCurrencies();
          this.getArrangeDepositOrderTakenGivenThrough();

          this.tradeForm.productType = "TIME DEPOSIT"
          this.tradeForm.dayCountBasis = "365"

          this.tradeForm.scfUser = this.user.name + " " + this.user.surname;

          this.tradeForm.startDate = moment().format("DD-MM-YYYY");

          this.tradeForm.instructionDate = moment().format("DD-MM-YYYY");
          this.tradeForm.instructionTime = moment()
            .endOf("day")
            .format("HH:mm:ss")
            .toString();

          //set start && end date name
          let startDate = new Date(
            this.MDYdateFormat(this.tradeForm.startDate)
          );
          this.tradeForm.startDateName = this.weekday[startDate.getDay()];


          this.tradeForm.endDate = moment()
            .add("days", 1)
            .format("DD-MM-YYYY ");

          let endDate = new Date(
            this.MDYdateFormat(this.tradeForm.endDate)
          );
          //set end date name
          this.tradeForm.endDateName = this.weekday[
            endDate.getDay()
          ];

          // hack textare row count
          setTimeout(function() {
            let textarea = document.getElementById("form-textarea");
            if (textarea) {
              textarea.rows = 1;
            }
          }, 200);
        }
      },
    },
    "tradeForm.buySell": {
      handler: function(val, before) {},
      deep: true,
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}


.endDateName .input-group-append .input-group-text {
  color: red;
}

.startDateName .input-group-append .input-group-text {
  color: red;
}


/* //vue select dropdown */
.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.shortcut-buttons-flatpickr-button {
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

</style>
